.block_hero_home {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;

    &.has_element:before {
        height: 28px;
        z-index: 4;

        @include breakpoint(xlarge) {
            height: 120px;
        }
    }

    video {
        object-fit: cover;
        z-index: 2;
    }

    picture,
    video {
        width: 100%;
        height: calc(100vh - 96px);
        position: absolute;

        @include breakpoint(large) {
            height: 100%;
        }
    }

    picture {
        z-index: 1;
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlay {
        background: linear-gradient(180deg, rgba($color-black, 0.8) 0%, rgba($color-black, 0.55) 100%);
        width: 100%;
        height: calc(100vh - 96px);
        position: absolute;
        z-index: 3;

        @include breakpoint(large) {
            height: 100%;
        }
    }

    .grid-container {
        height: calc(100vh - 96px);
        position: relative;
        z-index: 4;

        @include breakpoint(large) {
            height: 100vh;
        }
    }

    .grid-x {
        height: 100%;
        position: relative;
    }

    .title_prefix,
    .heading-h1,
    p {
        opacity: 0;
        transform: translateY(55px);
    }

    .title_prefix {
        margin-bottom: 16px;
    }

    .heading-h1-hero {
        padding-bottom: 8px;
    }

    .heading-h1 {
        margin-bottom: 24px;
    }

    p {
        margin-bottom: 48px;
    }

    .button {
        margin: 8px 0;
        opacity: 0;

        @include breakpoint(large) {
            margin: 0 4px;
        }
    }

    .usps {
        display: flex;
        list-style: none;
        margin-bottom: 0;
        margin-left: 0;
        position: relative;
        z-index: 4;

        @include breakpoint(medium down) {
            flex-direction: column;
            padding: 0 16px;
        }

        @include breakpoint(large) {
            position: absolute;
            right: 0;
            bottom: 32px;
        }

        @include breakpoint(xxlarge) {
            right: calc(50% - #{$global-width} / 12 * 6);
        }

        li {
            display: flex;
            align-items: center;
            min-height: 48px;
            position: relative;

            @include breakpoint(medium down) {
                border-bottom: rgba($color-white, 0.25) solid 1px;
                padding-top: 16px;
                padding-bottom: 16px;
            }

            @include breakpoint(large) {
                border-right: rgba($color-white, 0.25) solid 1px;
                margin-left: 24px;
                padding-right: 24px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                border: none;
            }
        }

        img {
            width: 30px;
            height: auto;
            margin-right: 8px;
            position: relative;
        }
    }

    .readmore-holder {
        position: absolute;
        bottom: 28px;
        left: 16px;
        z-index: 4;
    }

    .readmore {
        display: inline-flex;
        align-items: center;
        padding: 16px 32px 16px 64px;

        &:before {
            content: '';
            border-radius: 100px;
            width: 48px;
            height: 48px;
            display: block;
            position: absolute;
            left: 0;
            transition: all 350ms ease;
        }

        &:after {
            content: '';
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: 14px;
            transition: all 350ms ease;
        }
    }
}

;@import "sass-embedded-legacy-load-done:251";
//  Foundation for Sites Settings
//  -----------------------------

@import '../../../../node_modules/foundation-sites/scss/util/util';

// 1. Global
// ---------

$global-width: rem-calc(1260);
$body-antialiased: true;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

// 2. Breakpoints
// --------------

$breakpoints: (
        small: 0,
        medium: 640px,
        large: 768px,
        xlarge: 1024px,
        xxlarge: 1200px,
        xxxlarge: 1440px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
        small: 24px,
        large: 32px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

// 7. Accordion
// ------------
$accordion-plusminus: true;

// 15. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: (
        small: 0.66rem,
        large: 1rem,
);
$closebutton-offset-vertical: (
        small: 0.33em,
        large: 0.5rem,
);
$closebutton-size: (
        small: 1.5em,
        large: 2em,
);

// 19. Flexbox Utilities
// ---------------------

$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

// 27. Pagination
// --------------

$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: true;

// 47. Reveal
// ----------

$reveal-width: 600px;
$reveal-max-width: $global-width;
$reveal-zindex: 1005;

// 56. Xy Grid
// -----------

$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
        small: 32px
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;
$xy-block-grid-max: 8;

;@import "sass-embedded-legacy-load-done:7";
.block_collage {
    .content {
        margin-top: 301px;
        margin-bottom: 48px;

        @include breakpoint(large) {
            margin-top: 450px;
        }

        @include breakpoint(xlarge) {
            margin-top: 608px;
            margin-bottom: 141px;
        }

        .heading-h2 {
            margin-bottom: 20px;

            @include breakpoint(xlarge) {
                margin-top: 64px;
                margin-bottom: 32px;
            }
        }

        .button {
            margin-top: 16px;
        }
    }

    picture {
        position: absolute;
        top: 48px;
        width: 50vw;
        height: 185px;

        @include breakpoint(large) {
            height: 300px;
        }

        @include breakpoint(xlarge) {
            top: 96px;
            height: calc(100% - 350px);
        }
    }

    .grid-x {
        position: relative;
    }

    .top {
        position: absolute;
        top: 60px;
        width: 50vw;
        height: 100px;
        opacity: 0;
        transform: translateY(-100px);

        @include breakpoint(large) {
            height: 250px;
        }

        @include breakpoint(xlarge) {
            top: 144px;
            width: 616px;
            height: 400px;
            transform: translateY(-255px);
        }
    }

    .bottom {
        position: absolute;
        width: 128px;
        height: 80px;
        opacity: 0;

        @include breakpoint(large down) {
            top: 193px;
        }

        @include breakpoint(large) {
            height: 200px;
            width: 35vw;
            right: 44px;
        }

        @include breakpoint(xlarge) {
            right: calc(calc(#{$global-width} / 12 * 1 - 32px));
            bottom: 96px;
            width: 512px;
            height: 320px;
            transform: translateX(155px);
        }
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &:not(.reversed) {
        picture {
            right: 0;
        }

        .top {
            left: 0;

            @include breakpoint(xlarge) {
                left: calc(#{$global-width} / 12 * 1);
            }
        }

        .bottom {
            right: 44px;
            transform: translateX(100px);

            @include breakpoint(xlarge) {
                right: calc(calc(#{$global-width} / 12 * 1 - 32px));
                transform: translateX(155px);
            }
        }
    }

    &.reversed {
        picture {
            left: 0;
        }

        .top {
            right: 0;

            @include breakpoint(xlarge) {
                right: calc(#{$global-width} / 12 * 1);
            }
        }

        .bottom {
            left: 44px;
            transform: translateX(-100px);

            @include breakpoint(xlarge) {
                left: calc(calc(#{$global-width} / 12 * 1 - 32px));
                transform: translateX(-155px);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:267";
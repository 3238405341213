.block_dealer_locator {
    margin: 64px 0;

    @include breakpoint(xlarge) {
        .locator {
            margin-top: 32px;
        }
    }

    @include breakpoint(large down) {
        .heading-h2 {
            margin-bottom: 16px;
        }
    }

    #map-search {
        align-self: flex-start;
        position: relative;
        margin-top: 24px;
        padding: 32px 0;
        z-index: 2;

        @include breakpoint(xlarge) {
            margin-top: 48px;
        }

        @include breakpoint(large down) {
            padding-right: 16px;
        }

        &:before {
            content: '';
            width: calc(100% + 16px);
            height: 100%;
            position: absolute;
            top: 0;
            left: -16px;
            z-index: -1;

            @include breakpoint(xlarge) {
                width: calc(100% + #{$global-width} / 12 * 1.5);
                left: calc(0 - #{$global-width} / 12 * 1);
            }
        }

        .heading-h4 {
            margin-bottom: 12px;
        }

        @include breakpoint(large) {
            .form-element {
                flex-direction: row;
                gap: 15px;
            }
        }

        .field-wrapper {
            padding: 12px 0;
            width: 100%;
        }
    }

    .locations {
        margin-top: 32px;
        display: none;

        @include breakpoint(xlarge) {
            margin-top: calc(-100% + 330px);
        }
        @include breakpoint(xxlarge) {
            margin-top: calc(-100% + 265px);
        }
    }

    .location-list {
        max-height: 1100px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        padding-right: 16px;

        @include breakpoint(xlarge) {
            width: calc(100% - 16px);
        }

        .heading-h5 {
            margin-bottom: 20px;
        }

        .location {
            margin: 6px 0;
            padding: 20px;
            position: relative;

            .name {
                max-width: 90%;

                @include breakpoint(small only) {
                    padding-right: 48px;
                }
            }
        }

        .distance {
            min-width: 60px;
            position: absolute;
            top: 16px;
            right: 16px;
        }

        .usps {
            display: flex;

            li {
                margin-right: 12px;
                padding-left: 18px;
                position: relative;

                &:before {
                    content: '';
                    background-size: auto 100%;
                    width: 13px;
                    height: 10px;
                    display: block;
                    position: absolute;
                    top: 6px;
                    left: 0;
                }

                &:empty {
                    display: none;
                }
            }
        }

        .location__actions {
            display: flex;
            padding-top: 20px;
            justify-content: space-between;

            @include breakpoint(small only) {
                flex-direction: column-reverse;
            }

            @include breakpoint(small only) {
                .button--primary {
                    justify-content: center;
                }

                .button--tertiary {
                    margin: 16px auto auto;
                }
            }
        }
    }

    #map_canvas {
        width: 100%;
        aspect-ratio: 1 / 1;

        @include breakpoint(large down) {
            width: calc(100% + 32px);
            margin-top: -4px;
            margin-left: -16px;
        }
    }
}

#dealer-contact-form label[for=form-input-dealerName],
#dealer-contact-form label[for=form-input-productRange],
#dealer-contact-form label[for=form-input-fireplaceOfType],
#dealer-contact-form label[for=form-input-model],
#dealer-contact-form #form-input-dealerName,
#dealer-contact-form #form-input-fireplaceOfType,
#dealer-contact-form #form-input-model,
#dealer-contact-form #form-input-productRange {
    display: none;
}

;@import "sass-embedded-legacy-load-done:287";
.product_title {
    padding: 16px 0;

    @include breakpoint(xlarge) {
        padding: 8px 0;
    }
}

.block_product_configurator {
    @include breakpoint(xlarge) {
        display: flex;
        height: 810px;

        .grid-container {
            width: 100%;
        }

        .grid-x {
            height: 100%;
            align-items: center;
            justify-content: right;
        }
    }

    picture {
        transition: opacity 850ms ease;

        @include breakpoint(xlarge) {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        &.hide-image {
            opacity: 0;

            @include breakpoint(large down) {
                display: none;
            }
        }

        img {
            width: 100%;

            @include breakpoint(xlarge) {
                object-fit: cover;
                object-position: center;
                height: 100%;
                position: absolute;
            }
        }
    }

    .configurator {
        padding-top: 32px;
        padding-bottom: 32px;
        position: relative;
        background-color: $color-white;

        @include breakpoint(xlarge) {
            padding: 32px 40px;
        }

        .heading-h4 {
            margin-bottom: 24px;
        }

        .model-list {
            margin-bottom: 32px;

            @include breakpoint(xlarge) {
                max-height: 192px;
                margin-bottom: 32px;
                overflow: auto;
            }
        }

        .description {
            text-transform: uppercase;
        }

        .custom-radio {
            text-transform: capitalize;
            height: 60px;
            padding-left: 40px;
            display: flex;
            align-items: center;

            &:not(:last-child){
                margin: 0 0 6px 0;
            }

            span.checkmark {
                top: 50%;
                margin-left: 16px;
                transform: translateY(-50%);
            }
        }

        .dimension-holder {
            position: relative;
        }

        .dimension-label {
            position: relative;
            display: block;
            width: 100%;
            margin-top: 4px;
            padding: 12px;
            cursor: pointer;
        }

        .dimension-list {
            list-style-type: none;
            margin: 0;
            border-top: none;
            display: none;
            cursor: pointer;
            transition: all 350ms ease;

            &.open {
                display: block;
                position: absolute;
                width: 100%;
                z-index: 1;
                background: $color-white;
                transition: all 350ms ease;
            }

            li {
                padding: 12px;
                transition: all 200ms ease;
            }
        }

        .information-holder {
            margin-top: 24px;

            &.hide-information {
                opacity: 0;
                max-height: 0;
                transition: max-height 350ms ease-in;
            }

            .button {
                width: 100%;
                justify-content: center;

                &.orange {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }
        }
    }
}

#product-mail-form .button--tertiary {
    display: none;
}

;@import "sass-embedded-legacy-load-done:286";
.block_image_four_columns {
    padding: 48px 0;

    @include breakpoint(xlarge) {
        padding: 96px 0;
    }

    &.has_animation .item {
        opacity: 0;
    }

    .top {
        justify-content: space-between;
        margin-bottom: 32px;

        @include breakpoint(xlarge) {
            margin-bottom: 64px;
        }
    }

    .progress {
        margin-top: 32px;
    }

    .item {
        padding: 0 2px;
        margin-bottom: 32px;
        position: relative;
        cursor: pointer;

        @include breakpoint(xlarge) {
            padding: 0 4px;
        }

        &.has_second:hover {
            .primary-img {
                opacity: 0;
            }

            .secondary-img {
                opacity: 1;
            }
        }

        .icon {
            position: absolute;
            top: 12px;
            left: 14px;
            width: 32px;
            height: 32px;
            z-index: 1;

            @include breakpoint(xlarge) {
                left: 16px;
                width: 48px;
                height: 48px;
            }
        }

        .primary-img {
            opacity: 1;
            transition: opacity 850ms ease;
        }

        .secondary-img {
            position: absolute;
            top: 0;
            left: 2px;
            width: calc(100% - 4px);
            height: 100%;
            opacity: 0;
            transition: opacity 850ms ease;

            @include breakpoint(xlarge) {
                left: 4px;
                width: calc(100% - 8px);
            }
        }

        .button {
            position: absolute;
            justify-content: center;
            bottom: 6px;
            left: 10px;
            width: calc(100% - 20px);

            @include breakpoint(xlarge) {
                bottom: 16px;
                left: 20px;
                width: calc(100% - 40px);
            }
        }
    }

    .splide__track {
        overflow: visible;

        @include breakpoint(xlarge down) {
            &.hasOverflow {
                width: 85%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:274";
nav.navigation {
    @include breakpoint(large down) {
        background-color: $color-grey-dark-one;
    }

    ul.mainnav > li > a,
    ul.mainnav-right li a {
        color: $color-white;

        @include breakpoint(xlarge) {
            color: $color-grey-light-two;
        }

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    ul.mainnav > li {
        @include breakpoint(large down) {
            border-bottom: rgba($color-white, 0.15) solid 1px;
        }

        > a {
            font-size: $font-menu;
            letter-spacing: 0.04em;
        }
    }

    ul.mainnav-right li a {
        font-size: $font-button;
        text-transform: uppercase;
        letter-spacing: 0.08em;
    }

    .submenu {
        @include breakpoint(xlarge) {
            background-color: $color-white;
            border: 1px solid $color-form-borders;
        }

        li a {
            color: $color-white;
            font-size: $font-s;
            letter-spacing: 0.04em;
            transition: all 100ms ease-out;

            @include breakpoint(xlarge) {
                color: $color-text;
            }

            &:hover {
                color: $color-text-light;
            }
        }
    }

    //Active menu item
    ul.mainnav > li.is-active > a,
    ul.mainnav-right li.is-active a {
        color: $color-white;
    }

    ul.mainnav li.is-active > a:after {
        content: '';
        background-color: $color-primary;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -4px;
        left: 0;
    }

    .mainnav span {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4xMjEiIGhlaWdodD0iMTEuMTgyIiB2aWV3Qm94PSIwIDAgMTguMTIxIDExLjE4MiI+CiAgPHBhdGggaWQ9IlBhdGhfMTk2IiBkYXRhLW5hbWU9IlBhdGggMTk2IiBkPSJNNjMzNCw5NjI0bDgsOCw4LTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MzMyLjkzOSAtOTYyMi45MzkpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMyIvPgo8L3N2Zz4K");
        transition: 200ms ease-out;
    }
}

;@import "sass-embedded-legacy-load-done:297";
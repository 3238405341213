.heading-h1-hero {
    @include font-heading-hero-h1;
}

.heading-h1,
h1 {
    @include font-heading-h1;
    color: $color-black;
}

.heading-h2,
h2 {
    @include font-heading-h2;
    color: $color-black;
}

.heading-h3,
h3 {
    @include font-heading-h3;
    color: $color-black;
}

.heading-h4,
h4 {
    @include font-heading-h4;
    color: $color-black;
}

.heading-h5,
h5 {
    @include font-heading-h5;
    color: $color-black;
}

.heading-h6,
h6 {
    @include font-heading-h6;
    color: $color-black;
}

.font-text-l {
    @include font-text-l;
}

.font-text-m {
    @include font-text-m;
}

.font-text-s {
    @include font-text-s;
}

blockquote,
.font-quote,
blockquote p {
    @include font-quote;
    color: $color-primary;
    font-style: italic;
    border-left: none;
}

.title_prefix {
    color: $color-primary;
    font-size: $font-menu;
    font-weight: $medium;
    line-height: 150%;
    display: block;
}

;@import "sass-embedded-legacy-load-done:292";
.block_download {
    padding-top: 30px;

    .content {
        z-index: 1;

        @include breakpoint(medium down){
            order: 2;
        }

        @include breakpoint(large) {
            margin-top: 80px;
        }

        figure {
            margin-bottom: 12px;

            img {
                width: 40px;
                height: 40px;

                @include breakpoint(large) {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .heading-h2 {
            margin-bottom: 20px;

            @include breakpoint(large) {
                margin-bottom: 32px;
            }
        }
    }

    .image {
        z-index: 1;
        margin-bottom: 32px;

        img {
            width: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:262";
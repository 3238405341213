.block_dealer_locator {
    #map-search {
        &:before {
            background-color: rgba($color-primary, 0.85);
        }

        select {
            background-color: $color-white;
        }

        label {
            font-size: $font-form;
            font-weight: $bold;
            line-height: 150%;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    @include breakpoint(xlarge) {
        form {
            padding-right: 32px;
        }
    }

    .location {
        border: $color-grey-light-two solid 1px;
        color: $color-grey-dark-three;

        .name {
            color: $color-black;
            font-size: $font-l;
        }
    }

    .distance {
        border: $color-grey-light-two solid 1px;
        font-size: $font-s;
        border-radius: 30px;
        padding: 5px;
        text-align: center;

        &:empty {
            display: none;
        }
    }

    .location-list {
        &::-webkit-scrollbar-track {
            background-color: $color-grey-light-one;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: $color-grey-light-three;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-light-three;
        }

        ul li {
            font-size: $font-s;
            text-transform: capitalize;

            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExLjA0NS43NTggNC4zNjcgNy40MzYgMS45MyA0Ljk3M2MtLjEyNy0uMTAyLS4zMy0uMTAyLS40MzIgMGwtLjczNi43MzZjLS4xMDIuMTAyLS4xMDIuMzA1IDAgLjQzMmwzLjQwMiAzLjM3N2EuMjkyLjI5MiAwIDAgMCAuNDMyIDBMMTIuMjEzIDEuOWMuMTAyLS4xMDEuMTAyLS4zMDQgMC0uNDMxbC0uNzM2LS43MTFjLS4xMDItLjEyNy0uMzA1LS4xMjctLjQzMiAwWiIgZmlsbD0iI0YwODQ2NiIvPjwvc3ZnPg==");
            }
        }
    }

    .location__actions {
        border-top: $color-grey-light-two solid 1px;

        .button {
            font-size: $font-form;
        }
    }

    .info_window .name {
        @include font-heading-h6;
        display: block;
        margin-bottom: 8px;
    }
}

;@import "sass-embedded-legacy-load-done:314";
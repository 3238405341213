.block_download {
    @include breakpoint(medium down){
        &:after {
            content: '';
            background-color: rgba($color-primary, 0.85);
            width:  calc(100% - 80px);
            height: 100%;
            top: 0;
            left: 80px;
            position: absolute;
        }
    }

    @include breakpoint(large) {
        .grid-x {
            position: relative;

            &:after {
                content: '';
                background-color: rgba($color-primary, 0.85);
                width: 100vw;
                height: calc(100% - 160px);
                bottom: 0;
                left: 0;
                position: absolute;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:305";
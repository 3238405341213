form {
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=number],
    input[type=file],
    select,
    textarea {
        font-size: $font-s;
        font-weight: $regular;
        color: $color-black;
        line-height: 22px;
        border: $color-grey-light-two solid 1px;

        &:hover,
        &:focus {
            border-color: $color-primary;
        }

        &::placeholder {
            opacity: 0;
        }
    }

    select {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS45OTk5NCA0LjY4OTM0TDEwLjQ2OTYgMC4yMTk2NjZMMTEuNTMwMyAxLjI4MDMzTDUuOTk5OTQgNi44MTA2NkwwLjQ2OTYxNCAxLjI4MDMzTDEuNTMwMjcgMC4yMTk2NjZMNS45OTk5NCA0LjY4OTM0WiIgZmlsbD0iIzAwMUYzMiIvPgo8L3N2Zz4K");
        background-size: 11px 7px;
        background-position: calc(100% - 16px) center;
        background-color: transparent;
    }

    span.error {
        font-size: $font-s;
        color: $color-error;
        line-height: 20px;
    }

    .form-group {
        label:not(.custom-checkbox) {
            font-size: $font-form;
            font-weight: $bold;
            line-height: 150%;
            letter-spacing: 1px;
            color: $color-grey-dark-one;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        .custom-checkbox {
            color: $color-grey-dark-three;
        }

        .custom-checkbox .checkmark {
            top: 6px;
            border-radius: 2px;
            border-color: rgba($color-grey-dark-one, 0.3);
        }

        &.error input {
            border-color: $color-error;
        }
    }
}

.custom-checkbox,
.custom-radio {
    @include font-text-s;
    line-height: 27px;
    text-transform: none;
    font-weight: $regular;

    .checkmark {
        font-size: $font-s;
        background-color: transparent;
        border: $color-black solid 1px;
    }
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: $color-black;
    border-color: $color-black;

    &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4yOTUgMi4xNjE4Nkw0LjQ0NDI0IDkuMDEyNjNMMC43MDQ1OSA1LjI3Mjk3TDEuOTYxNjcgNC4wMTU5TDQuNDQ0MjQgNi40OTg0N0wxMC4wMzc5IDAuOTA0Nzg1TDExLjI5NSAyLjE2MTg2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
    }
}

.custom-radio {
    .checkmark {
        border-radius: 50%;
    }

    input:checked ~ .checkmark {
        background-color: $color-white;
        border-color: $color-black;
        box-shadow: inset 0 0 0 4px $color-black;
    }
}

;@import "sass-embedded-legacy-load-done:296";
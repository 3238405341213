.block_product_configurator {
    .description {
        font-size: $font-form;
        font-weight: $bold;
        letter-spacing: 1px;
        line-height: 150%;
    }

    .custom-radio {
        border: 1px solid $color-grey-light-two;
        font-size: $font-m;

        &:after {
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.front:after {
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9477_13004)'%3E%3Cpath d='M17.9976 1.58128C8.32249 1.58128 0.576375 9.98982 1.67868 19.8776C2.5174 27.4118 8.58608 33.4726 16.1225 34.3111C26.0133 35.4131 34.4244 27.6693 34.4244 17.9971C34.4244 8.94174 27.0557 1.57529 18.0036 1.57529M18.0036 35.994C7.3999 35.994 -1.09506 26.777 0.115082 15.9369C1.03767 7.67807 7.68746 1.03029 15.9428 0.113973C26.7861 -1.08981 36 7.40257 36 18.0031C36 27.9268 27.9244 36 17.9976 36' fill='%231D1D1D'/%3E%3Cpath d='M28.7751 20.9014H7.50171V23.3812H28.7751V20.9014Z' fill='%231D1D1D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9477_13004'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &.freestanding:after {
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9477_13004)'%3E%3Cpath d='M17.9976 1.58128C8.32249 1.58128 0.576375 9.98982 1.67868 19.8776C2.5174 27.4118 8.58608 33.4726 16.1225 34.3111C26.0133 35.4131 34.4244 27.6693 34.4244 17.9971C34.4244 8.94174 27.0557 1.57529 18.0036 1.57529M18.0036 35.994C7.3999 35.994 -1.09506 26.777 0.115082 15.9369C1.03767 7.67807 7.68746 1.03029 15.9428 0.113973C26.7861 -1.08981 36 7.40257 36 18.0031C36 27.9268 27.9244 36 17.9976 36' fill='%231D1D1D'/%3E%3Cpath d='M28.7751 20.9014H7.50171V23.3812H28.7751V20.9014Z' fill='%231D1D1D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9477_13004'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &.corner:after {
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9477_13013)'%3E%3Cpath d='M18.0012 1.58161C8.32792 1.58161 0.583326 9.99153 1.68542 19.881C2.52397 27.4164 8.59146 33.4782 16.1204 34.3168C26.0093 35.419 34.4187 27.6739 34.4187 18.0001C34.4187 8.94328 27.0515 1.57562 18.0012 1.57562M18.0012 36C7.39354 36 -1.09976 26.7814 0.116138 15.9336C1.03854 7.67341 7.69301 1.03053 15.9467 0.114061C26.7879 -1.08992 36 7.39787 36 18.0001C36 27.9255 27.926 36 18.0012 36Z' fill='%231D1D1D'/%3E%3Cpath d='M26.9676 24.2902H8.96875V11.6514H11.4544V21.8104H26.9676V24.2902Z' fill='%231D1D1D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9477_13013'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &.threesided:after {
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9477_13022)'%3E%3Cpath d='M18 1.57537C8.9431 1.57537 1.58137 8.9431 1.58137 18C1.58137 27.0569 8.94909 34.4186 18 34.4186C27.0509 34.4186 34.4186 27.0509 34.4186 18C34.4186 8.94909 27.0509 1.57537 18 1.57537ZM18 36C8.07455 36 0 27.9255 0 18C0 8.07454 8.07455 0 18 0C27.9255 0 36 8.07454 36 18C36 27.9255 27.9255 36 18 36Z' fill='%231D1D1D'/%3E%3Cpath d='M27.6199 24.2835H8.38V11.6445H10.8659V21.8036H25.1401V11.6445H27.6199V24.2835Z' fill='%231D1D1D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9477_13022'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &.tunnel:after {
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9492_18278)'%3E%3Cpath d='M18 1.58136C8.94309 1.58136 1.58135 8.94909 1.58135 18C1.58135 27.0509 8.94908 34.4246 18 34.4246C27.0509 34.4246 34.4186 27.0569 34.4186 18C34.4186 8.9431 27.0509 1.58136 18 1.58136ZM18 36C8.07454 36 0 27.9255 0 18C0 8.07454 8.07454 0 18 0C27.9254 0 36 8.07454 36 18C36 27.9255 27.9254 36 18 36Z' fill='%231D1D1D'/%3E%3Cpath d='M28.776 21.8033H7.50543V24.2832H28.776V21.8033Z' fill='%231D1D1D'/%3E%3Cpath d='M28.776 11.8718H7.50543V14.3517H28.776V11.8718Z' fill='%231D1D1D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9492_18278'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        &.roomdivider:after {
            background-image: url("data:image/svg+xml,%3Csvg width='37' height='36' viewBox='0 0 37 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9492_18281)'%3E%3Cpath d='M25.2806 27.7258H11.0064V7.59375H13.4863V25.2409H22.7948V7.69628H25.2806V27.7258Z' fill='%231D1D1D'/%3E%3Cpath d='M18.0027 1.58016C8.94577 1.58016 1.58404 8.94421 1.58404 18.003C1.58404 27.0618 8.95176 34.4259 18.0027 34.4259C27.0536 34.4259 34.4213 27.0618 34.4213 18.003C34.4213 8.94421 27.0536 1.58016 18.0027 1.58016ZM18.0027 36C8.07722 36 0.00268555 27.9243 0.00268555 17.997C0.00268555 8.0697 8.07722 0 18.0027 0C27.9281 0 36.0027 8.07573 36.0027 17.997C36.0027 27.9182 27.9281 36 18.0027 36Z' fill='%231D1D1D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9492_18281'%3E%3Crect width='36' height='36' fill='white' transform='translate(0.00268555)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
    }

    .dimension-label {
        border: 1px solid $color-grey-light-two;
        font-size: $font-m;

        .choose {
            color: rgba($color-grey-dark-one, 0.5);
        }

        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.85355 4.14645C10.0488 4.34171 10.0488 4.65829 9.85355 4.85355L6.37197 8.33514C6.2782 8.42891 6.15102 8.48159 6.01841 8.48159C5.88581 8.48159 5.75863 8.42891 5.66486 8.33514L2.18327 4.85355C1.98801 4.65829 1.98801 4.34171 2.18327 4.14645C2.37854 3.95118 2.69512 3.95118 2.89038 4.14645L6.01841 7.27448L9.14645 4.14645C9.34171 3.95118 9.65829 3.95118 9.85355 4.14645Z' fill='%231D1D1D'/%3E%3C/svg%3E%0A");
            position: absolute;
            width: 12px;
            height: 12px;
            top: 19px;
            right: 12px;
            transform-origin: center;
            transition: transform 200ms ease-out;
        }

        &.open:after {
            transform: rotate(180deg);
        }
    }

    .dimension-list {
        border: 1px solid $color-grey-light-two;
        font-size: $font-m;

        li:hover {
            background-color: $color-grey-light-one;
        }
    }

    .model-list {
        &::-webkit-scrollbar-track {
            background-color: $color-grey-light-one;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: $color-primary;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
        }
    }

    .information-holder span {
        color: $color-grey-dark-three;
        font-weight: $bold;
    }
}

;@import "sass-embedded-legacy-load-done:313";
.block_image_text_small {
    padding: 48px 0;

    @include breakpoint(xlarge) {
        padding: 96px 0;
    }

    @include breakpoint(xlarge) {
        &.reversed {
            .image {
                order: 2;
            }

            .inner {
                padding: 80px 0 88px calc(#{$global-width} / 12 * 1);
            }
        }

        &:not(.reversed) {
            .image img {
                float: right;
            }

            .holder {
                margin-left: calc(#{$global-width} / 12 * -1);

                .inner {
                    padding: 80px 0 88px calc(#{$global-width} / 12 * 2);
                }
            }
        }
    }

    .holder {
        z-index: 1;

        @include breakpoint(large down) {
            order: 2;
        }

        .inner {
            @include breakpoint(large down) {
                padding: 48px 16px;
                margin-top: -48px;
            }

            @include breakpoint(xlarge) {
                width: calc(100% + #{$global-width} / 12 * 1);
                max-width: calc(100% + #{$global-width} / 12 * 1);

                .heading-svg {
                    width: 48px;
                    height: 48px;
                }

                .content {
                    max-width: calc(#{$global-width} / 12 * 4);
                }
            }

            .heading-h2 {
                margin-bottom: 20px;

                @include breakpoint(xlarge) {
                    margin-bottom: 32px;
                }
            }

            .button {
                margin: 6px 0;
            }

            @include breakpoint(small only) {
                .cta {
                    margin-top: 16px;

                    .button {
                        justify-content: center;
                        width: 100%;
                    }
                }
            }
        }
    }

    .image {
        @include breakpoint(large down) {
            padding: 0;
        }

        img {
            @include breakpoint(large down) {
                margin-left: -32px;
                min-width: calc(100% + 64px);
            }

            @include breakpoint(xlarge) {
                width: calc(100% + #{$global-width} / 12 * 2);
                max-width: calc(100% + #{$global-width} / 12 * 2);
            }
        }
    }

    .heading-h2,
    p,
    ul,
    .cta {
        opacity: 0;
        transform: translateY(50px);
    }
}

;@import "sass-embedded-legacy-load-done:256";
.dealer-list {
    margin: 48px 0;

    @include breakpoint(xlarge) {
        margin: 96px 0;
    }

    .heading {
        display: flex;
        
        @include breakpoint(large down) {
            align-items: center;
        }

        img {
            min-width: 48px;
            min-height: 48px;
        }

        .heading-h2 {
            margin-left: 12px;

            @include breakpoint(xlarge) {
                margin-left: 24px;
            }
        }
    }

    .list {
        margin-top: 48px;

        @include breakpoint(xlarge) {
            column-count: 4;
            column-fill: balance;
            column-gap: 32px;
            margin-top: 96px;
            padding-right: 32px;
        }

        .dealer-item {
            @include breakpoint(large down) {
                padding: 20px 0;
            }

            @include breakpoint(xlarge) {
                margin-bottom: 40px;
            }

            span {
                text-transform: uppercase;
                letter-spacing: 1px;
                display: block;

                @include breakpoint(xlarge) {
                    margin-bottom: 16px;
                }
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .js-accordion {
        cursor: pointer;
        width: 100%;
        text-align: left;
        outline: none;
        transition: 400ms;

        &.active span:after {
            transform: rotate(180deg);
        }

        span {
            display: block;
            position: relative;

            &:after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.1387 5.52827C13.3991 5.78862 13.3991 6.21073 13.1387 6.47108L8.49661 11.1132C8.37158 11.2382 8.20202 11.3085 8.0252 11.3085C7.84839 11.3085 7.67882 11.2382 7.5538 11.1132L2.91169 6.47108C2.65134 6.21073 2.65134 5.78862 2.91169 5.52827C3.17204 5.26792 3.59415 5.26792 3.8545 5.52827L8.0252 9.69898L12.1959 5.52827C12.4563 5.26792 12.8784 5.26792 13.1387 5.52827Z' fill='%231D1D1D'/%3E%3C/svg%3E%0A");
                background-size: 100% auto;
                background-repeat: no-repeat;
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                top: -1px;
                right: 3px;
                transform-origin: center;
                transition: transform 200ms ease-out;
            }
        }
    }

    .panel {
        overflow: hidden;

        @include breakpoint(large down) {
            display: none;
            padding: 16px 0;
        }

        @include breakpoint(xlarge) {
            display: block !important;
        }
    }
}

;@import "sass-embedded-legacy-load-done:288";
.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 800;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 150ms ease-in-out;

    &:before {
        content: '';
        background: linear-gradient(180deg, rgba($color-black, 0.8) 0%, rgba($color-black, 0.55) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }

    &.modal-open {
        z-index: 900;
        opacity: 1;
        visibility: visible;
        pointer-events: unset;
    }

    .modal__inner {
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        width: 700px;
        top: 50%;
        left: 50%;
        position: absolute;
        overflow: hidden;
        transform: translate(-50%, -50%);
        z-index: 3;

        @include breakpoint(medium down) {
            flex-direction: column;
            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow: auto;
        }

        @include breakpoint(xlarge) {
            width: 1100px;
        }
    }

    .modal__title {
        margin-bottom: 12px;
        margin-left: 16px;
    }

    .content {
        width: 100%;
        padding: 16px;

        @include breakpoint(medium) {
            padding: 32px 40px;
        }

        p {
            margin-left: 16px;
        }
    }

    .close {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.5' y='19.4346' width='23.2421' height='2.21354' rx='0.5' transform='rotate(-45 3.5 19.4346)' fill='%231D1D1D'/%3E%3Crect x='5.06494' y='3' width='23.2421' height='2.21354' rx='0.5' transform='rotate(45 5.06494 3)' fill='%231D1D1D'/%3E%3C/svg%3E%0A");
        background-size: 100% auto;
        display: block;
        position: absolute;
        top: 12px;
        right: 12px;
        width: 25px;
        height: 24px;
        cursor: pointer;
        z-index: 2;
    }
}

;@import "sass-embedded-legacy-load-done:247";
body {
    @include font-text-m;
    color: $color-text;
}

a {
    color: $color-primary;

    &:hover {
        color: $color-primary;
    }
}

ul.custom {
    @include custom-bullets($color-primary);
}

.bg-orange {
    background-color: rgba($color-primary, 0.85);
}

.bg-white {
    background-color: rgba($color-white, 0.85);
}

.bg-grey {
    background-color: $color-grey-light-one;
}

.bg-grey-dark {
    background-color: rgba($color-grey-dark-three, 0.85);
}

.bg-black {
    background-color: $color-black;
}

.bg-green {
    background-color: rgba($color-secondary-green, 0.85);
}

.bg-sand {
    background-color: rgba($color-secondary-sand, 0.85);
}

.bg-purple {
    background-color: rgba($color-secondary-purple-light, 0.85);
}

.bg-blue {
    background-color: rgba($color-secondary-blue, 0.85);
}

.breadcrumbs {
    transition: all 250ms ease-out;
    height: 53px;

    a,
    li {
        color: rgba($color-grey-dark-one, 0.6);
        font-size: $font-s;
        line-height: 150%;

        &:not(:last-child)::after {
            color: $color-grey-light-three;
        }

        &.current {
            color: $color-grey-dark-one;
        }

        @include breakpoint(xlarge) {
            color: rgba($color-grey-dark-one, 0.7);

            &.current {
                color: $color-grey-dark-one;
            }
        }
    }

    @include breakpoint(xlarge) {
        &:not(.transparent) {
            position: relative;

            &:after {
                content: '';
                background-color: $color-white;
                display: block;
                width: 200vw;
                height: 100%;
                top: 0;
                left: -100vw;
                position: absolute;
            }
        }

        &.transparent li {
            &.current {
                color: $color-white;
            }

            a {
                color: rgba($color-white, 0.7);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:293";
.block_image_slider {
    padding: 8px 0 32px;

    @include breakpoint(xlarge) {
        padding: 36px 0 64px;
    }

    .icon {
        margin-bottom: 12px;
    }

    .heading-h2 {
        margin-bottom: 32px;

        @include breakpoint(xlarge) {
            margin-bottom: 40px;
        }
    }

    .image-slider {
        width: 100%;

        .image-slider-inner {
            height: 160px;

            @include breakpoint(medium) {
                height: 280px;
            }

            @include breakpoint(large) {
                height: 400px;
            }

            @include breakpoint(xxlarge) {
                height: 700px;
            }

            .splide__track {
                height: 160px;

                @include breakpoint(medium) {
                    height: 280px;
                }

                @include breakpoint(large) {
                    height: 400px;
                }

                @include breakpoint(xxlarge) {
                    height: 700px;
                }
            }

            video {
                object-fit: cover;
                z-index: 2;
            }

            picture,
            video {
                width: 100%;
                height: 100%;
                position: absolute;
            }

            picture {
                z-index: 1;
                cursor: pointer;

                &:after {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23BBBBBB' version='1.1' id='Capa_1' width='800px' height='800px' viewBox='0 0 390.704 390.704' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M379.711,326.556L265.343,212.188c30.826-54.189,23.166-124.495-23.001-170.663c-55.367-55.366-145.453-55.366-200.818,0 c-55.365,55.366-55.366,145.452,0,200.818c46.167,46.167,116.474,53.827,170.663,23.001l114.367,114.369 c14.655,14.655,38.503,14.654,53.157,0C394.367,365.059,394.368,341.212,379.711,326.556z M214.057,214.059 c-39.77,39.771-104.479,39.771-144.25,0c-39.77-39.77-39.77-104.48,0-144.25c39.771-39.77,104.48-39.77,144.25,0 C253.828,109.579,253.827,174.29,214.057,214.059z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");                    background-size: 100% auto;
                    display: block;
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    width: 24px;
                    height: 24px;

                    @include breakpoint(xlarge){
                        top: 24px;
                        right: 24px;
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }
    }

    .splide__track {
        overflow: visible;

        @include breakpoint(xlarge down) {
            &.hasOverflow {
                width: 85%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:269";
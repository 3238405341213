.block_hero_multiple_columns {
    &.has_element:before {
        background-color: rgba($color-primary, 0.55);
    }

    .heading-h1,
    p {
        color: $color-white;
    }

    .readmore {
        color: $color-white;
        font-size: $font-button;
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 0.08em;

        &:before {
            border: $color-white solid 1px;
        }

        &:after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi40MzQ5IDExLjMyNTdDMTYuNzUzNiAxMS42NTc4IDE2Ljc0MjcgMTIuMTg1MyAxNi40MTA2IDEyLjUwNEwxMC41NzczIDE4LjEwMTVDMTAuMjU0OSAxOC40MTA5IDkuNzQ1NzggMTguNDEwOSA5LjQyMzM1IDE4LjEwMTVMMy41OTAwMSAxMi41MDRDMy4yNTc5MyAxMi4xODUzIDMuMjQ3MDUgMTEuNjU3OCAzLjU2NTcxIDExLjMyNTdDMy44ODQzNyAxMC45OTM2IDQuNDExODkgMTAuOTgyNyA0Ljc0Mzk3IDExLjMwMTRMMTAuMDAwMyAxNi4zNDUzTDE1LjI1NjcgMTEuMzAxNEMxNS41ODg4IDEwLjk4MjcgMTYuMTE2MyAxMC45OTM2IDE2LjQzNDkgMTEuMzI1N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS45NzM5NiAxNy4yNzczQzkuNTEzNzIgMTcuMjc3MyA5LjE0MDYyIDE2LjkwNDIgOS4xNDA2MiAxNi40NDRMOS4xNDA2MiAzLjMzMzEyQzkuMTQwNjIgMi44NzI4OSA5LjUxMzcyIDIuNDk5NzkgOS45NzM5NiAyLjQ5OTc5QzEwLjQzNDIgMi40OTk3OSAxMC44MDczIDIuODcyODkgMTAuODA3MyAzLjMzMzEyTDEwLjgwNzMgMTYuNDQ0QzEwLjgwNzMgMTYuOTA0MiAxMC40MzQyIDE3LjI3NzMgOS45NzM5NiAxNy4yNzczWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
        }

        &:hover {
            text-decoration: none;

            &:before {
                background-color: $color-white;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi40MzQ5IDExLjMyNTJDMTYuNzUzNiAxMS42NTczIDE2Ljc0MjcgMTIuMTg0OCAxNi40MTA2IDEyLjUwMzVMMTAuNTc3MyAxOC4xMDFDMTAuMjU0OSAxOC40MTA0IDkuNzQ1NzggMTguNDEwNCA5LjQyMzM1IDE4LjEwMUwzLjU5MDAxIDEyLjUwMzVDMy4yNTc5MyAxMi4xODQ4IDMuMjQ3MDUgMTEuNjU3MyAzLjU2NTcxIDExLjMyNTJDMy44ODQzNyAxMC45OTMxIDQuNDExODkgMTAuOTgyMiA0Ljc0Mzk3IDExLjMwMDlMMTAuMDAwMyAxNi4zNDQ4TDE1LjI1NjcgMTEuMzAwOUMxNS41ODg4IDEwLjk4MjIgMTYuMTE2MyAxMC45OTMxIDE2LjQzNDkgMTEuMzI1MloiIGZpbGw9IiMxRDFEMUQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05Ljk3Mzk2IDE3LjI3NzNDOS41MTM3MiAxNy4yNzczIDkuMTQwNjIgMTYuOTA0MiA5LjE0MDYyIDE2LjQ0NEw5LjE0MDYyIDMuMzMzMTJDOS4xNDA2MiAyLjg3Mjg5IDkuNTEzNzIgMi40OTk3OSA5Ljk3Mzk2IDIuNDk5NzlDMTAuNDM0MiAyLjQ5OTc5IDEwLjgwNzMgMi44NzI4OSAxMC44MDczIDMuMzMzMTJMMTAuODA3MyAxNi40NDRDMTAuODA3MyAxNi45MDQyIDEwLjQzNDIgMTcuMjc3MyA5Ljk3Mzk2IDE3LjI3NzNaIiBmaWxsPSIjMUQxRDFEIi8+Cjwvc3ZnPgo=");
                transform: translateY(6px);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:309";
.block_image_video {
    margin: 32px 0;

    @include breakpoint(xlarge) {
        margin: 96px 0;
    }

    .top {
        justify-content: space-between;
        margin-bottom: 48px;

        @include breakpoint(xlarge) {
            margin-bottom: 64px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .item {
        position: relative;

        .first,
        .second {
            position: relative;
        }

        @include breakpoint(large down) {
            .first {
                margin-bottom: 32px;
            }
        }

        video {
            width: 100%;
            object-fit: cover;
        }

        @include breakpoint(xlarge) {
            color: $color-white;

            &.split {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .first,
            .second {
                position: relative;
                height: 100%;

                img,
                video {
                    height: calc(100% - 16px);
                    aspect-ratio: 4/3;
                }
            }

            .second img,
            .second video {
                margin-top: 16px;
            }

            video {
                height: 100%;
            }
        }

        p {
            margin-top: 8px;

            @include breakpoint(xlarge) {
                margin-top: 16px;
            }
        }

        img {
            width: 100%;
        }

        .content {
            opacity: 0;
            transition: opacity 350ms ease;

            @include breakpoint(large down) {
                margin-top: 12px;
                margin-bottom: 16px;
            }

            @include breakpoint(xlarge) {
                position: absolute;
                bottom: 32px;
                left: 32px;
                max-width: 300px;

                .heading-h3,
                .heading-h4 {
                    color: $color-white;
                }
            }

            .button {
                margin-top: 16px;

                @include breakpoint(xlarge) {
                    color: $color-white;

                    &:before {
                        border: $color-white solid 1px;
                    }

                    &:after {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1MzhDMTEuNjU3MyAzLjI0NjcyIDEyLjE4NDkgMy4yNTc2MSAxMi41MDM1IDMuNTg5NjhMMTguMTAxMSA5LjQyMzAyQzE4LjQxMDUgOS43NDU0NSAxOC40MTA1IDEwLjI1NDUgMTguMTAxMSAxMC41NzdMMTIuNTAzNSAxNi40MTAzQzEyLjE4NDkgMTYuNzQyNCAxMS42NTczIDE2Ljc1MzMgMTEuMzI1MyAxNi40MzQ2QzEwLjk5MzIgMTYuMTE2IDEwLjk4MjMgMTUuNTg4NCAxMS4zMDEgMTUuMjU2NEwxNi4zNDQ5IDEwTDExLjMwMSA0Ljc0MzY0QzEwLjk4MjMgNC40MTE1NiAxMC45OTMyIDMuODg0MDQgMTEuMzI1MyAzLjU2NTM4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDEwLjAyNkMxNy4yNzc0IDEwLjQ4NjMgMTYuOTA0MyAxMC44NTk0IDE2LjQ0NDEgMTAuODU5NEwzLjMzMzE4IDEwLjg1OTRDMi44NzI5NSAxMC44NTk0IDIuNDk5ODUgMTAuNDg2MyAyLjQ5OTg1IDEwLjAyNkMyLjQ5OTg1IDkuNTY1OCAyLjg3Mjk1IDkuMTkyNzEgMy4zMzMxOCA5LjE5MjcxTDE2LjQ0NDEgOS4xOTI3MUMxNi45MDQzIDkuMTkyNzEgMTcuMjc3NCA5LjU2NTggMTcuMjc3NCAxMC4wMjZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
                    }

                    &:hover {
                        &:before {
                            background-color: $color-white;
                        }

                        &:after {
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1NEMxMS42NTczIDMuMjQ2NzQgMTIuMTg0OSAzLjI1NzYyIDEyLjUwMzUgMy41ODk3TDE4LjEwMTEgOS40MjMwM0MxOC40MTA1IDkuNzQ1NDcgMTguNDEwNSAxMC4yNTQ2IDE4LjEwMTEgMTAuNTc3TDEyLjUwMzUgMTYuNDEwM0MxMi4xODQ5IDE2Ljc0MjQgMTEuNjU3MyAxNi43NTMzIDExLjMyNTMgMTYuNDM0NkMxMC45OTMyIDE2LjExNiAxMC45ODIzIDE1LjU4ODQgMTEuMzAxIDE1LjI1NjRMMTYuMzQ0OSAxMEwxMS4zMDEgNC43NDM2NkMxMC45ODIzIDQuNDExNTggMTAuOTkzMiAzLjg4NDA1IDExLjMyNTMgMy41NjU0WiIgZmlsbD0iIzFEMUQxRCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjI3NzQgMTAuMDI2QzE3LjI3NzQgMTAuNDg2MyAxNi45MDQzIDEwLjg1OTQgMTYuNDQ0MSAxMC44NTk0TDMuMzMzMTggMTAuODU5NEMyLjg3Mjk1IDEwLjg1OTQgMi40OTk4NSAxMC40ODYzIDIuNDk5ODUgMTAuMDI2QzIuNDk5ODUgOS41NjU4IDIuODcyOTUgOS4xOTI3MSAzLjMzMzE4IDkuMTkyNzFMMTYuNDQ0MSA5LjE5MjcxQzE2LjkwNDMgOS4xOTI3MSAxNy4yNzc0IDkuNTY1OCAxNy4yNzc0IDEwLjAyNloiIGZpbGw9IiMxRDFEMUQiLz4KPC9zdmc+Cg==");
                        }
                    }
                }
            }
        }
    }
}



;@import "sass-embedded-legacy-load-done:279";
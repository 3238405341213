header.header {
    @include breakpoint(xlarge) {
        .navigation-holder {
            padding-bottom: 24px;
        }

        &:not(.transparent) {
            background-color: $color-grey-dark-one;
        }
    }

    @include breakpoint(large down) {
        &:not(.homepage) .top {
            background-color: $color-grey-dark-one;
        }
    }

    .topmenu {
        .usp,
        a {
            color: $color-grey-light-two;
            font-size: $font-button;
            letter-spacing: 0.08em;
        }

        .usp {
            text-transform: uppercase;

            @include breakpoint(medium down) {
                font-size: $font-xs;
                line-height: 120%;
            }

            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjA0NDkgMC43NTc4MTJMNC4zNjcxOSA3LjQzNTU1TDEuOTI5NjkgNC45NzI2NkMxLjgwMjczIDQuODcxMDkgMS41OTk2MSA0Ljg3MTA5IDEuNDk4MDUgNC45NzI2NkwwLjc2MTcxOSA1LjcwODk4QzAuNjYwMTU2IDUuODEwNTUgMC42NjAxNTYgNi4wMTM2NyAwLjc2MTcxOSA2LjE0MDYyTDQuMTY0MDYgOS41MTc1OEM0LjI5MTAyIDkuNjQ0NTMgNC40Njg3NSA5LjY0NDUzIDQuNTk1NyA5LjUxNzU4TDEyLjIxMjkgMS45MDAzOUMxMi4zMTQ1IDEuNzk4ODMgMTIuMzE0NSAxLjU5NTcgMTIuMjEyOSAxLjQ2ODc1TDExLjQ3NjYgMC43NTc4MTJDMTEuMzc1IDAuNjMwODU5IDExLjE3MTkgMC42MzA4NTkgMTEuMDQ0OSAwLjc1NzgxMloiIGZpbGw9IiNGMDg0NjYiLz4KPC9zdmc+Cg==");
            }
        }
    }

    .language-selector {
        color: $color-grey-light-two;
        font-size: $font-button;
        text-transform: uppercase;

        ul {
            background-color: $color-grey-dark-one;
        }
    }

    .logo {
        path,
        rect {
            fill: $color-white;
        }
    }

    &.isScrolled {
        &.transparent {
            background-color: $color-grey-dark-one;
        }
    }

    &.menu-open {
        background-color: $color-grey-dark-one;
    }
}

;@import "sass-embedded-legacy-load-done:294";
.dealer-list {
    @include breakpoint(xlarge) {
        .list {
            column-rule: 1px solid $color-grey-light-two;
            border-right: 1px solid $color-grey-light-two;
        }
    }

    .dealer-item {
        @include breakpoint(large down) {
            border-top: 1px solid $color-grey-light-two;

            &:last-child {
                border-bottom: 1px solid $color-grey-light-two;
            }
        }

        span {
            color: $color-grey-dark-one;
            font-weight: $bold;
            font-size: $font-button;
        }

        li {
            color: $color-grey-dark-three;
        }
    }
}

;@import "sass-embedded-legacy-load-done:315";
.block_image_motion {
    ul.usps {
        font-size: $font-m;
        @include custom-bullets-image("checkmark-white.svg", 15px, 12px);

       li:before{
           top: 9px;
       }
    }
}

;@import "sass-embedded-legacy-load-done:306";
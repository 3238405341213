.block_content-image-switch {
    margin: 52px 0;

    @include breakpoint(large) {
        margin: 84px 0;
    }

    img {
        width: 100%;
    }

    .heading-h2 {
        margin-bottom: 20px;

        @include breakpoint(large){
            margin-bottom: 32px;
        }
    }

    .image {
        order: 1;
        position: relative;

        @include breakpoint(medium down) {
            margin-bottom: 36px;
        }
    }

    .content {
        order: 2;
    }

    &.reversed {
        .image {
            order: 1;

            @include breakpoint(large) {
                order: 2;
            }
        }

        .content {
            order: 2;

            @include breakpoint(large) {
                order: 1;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:285";
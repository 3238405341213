nav.navigation {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    transition: all 300ms ease-out;
    transform: translateX(0);

    @include breakpoint(large down) {
        box-sizing: border-box;
        display: block;
        top: 106px;
        right: 0;
        width: 100%;
        height: calc(100vh - 106px);
        margin: 0;
        position: fixed;
        transform: translateY(calc(-100% - 106px));
        transition: transform 300ms ease-out;
        user-select: none;
        z-index: 10;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        overflow: auto;
        list-style: none;
    }

    ul.mainnav,
    ul.mainnav-right {
        display: flex;
        margin-bottom: 0;

        a {
            position: relative;
            transition: color 250ms ease-out;
        }
    }

    ul.mainnav {
        @include breakpoint(large down) {
            flex-direction: column;
        }

        @include breakpoint(xlarge) {
            padding-left: 32px;
        }

        @include breakpoint(xxlarge) {
            padding-left: 64px;
        }

        li {
            position: relative;

            @include breakpoint(large down) {
                margin: 0 16px;
                padding: 20px 0;
            }

            @include breakpoint(xlarge) {
                margin: 0 4px;
            }

            @include breakpoint(xxlarge) {
                margin: 0 12px;
            }
        }

        > li {
            line-height: 40px;

            @include breakpoint(xlarge) {
                &:hover {
                    span {
                        transform: rotate(180deg);
                    }

                    .submenu {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    ul.mainnav-right {
        @include breakpoint(large down) {
            padding: 8px 16px 96px 16px;
        }

        li {
            margin: 0 4px;

            @include breakpoint(xxlarge) {
                margin: 0 6px;
            }

            svg {
                transform: translateY(5px);
            }
        }
    }

    ul.submenu {
        max-height: 0;
        overflow: hidden;
        margin-left: 0;
        position: relative;
        z-index: 10;
        transition: max-height 200ms ease-out;

        @include breakpoint(xlarge) {
            opacity: 0;
            position: absolute;
            border-radius: 8px;
            margin-left: 0;
            visibility: hidden;
            max-height: inherit;
            overflow: visible;
            padding: 8px 24px;
            top: 40px;
            left: -24px;
            transition: all 200ms ease-out;
        }

        li {
            display: block;
            margin: 0;
            position: relative;
            border: none;
            padding: 12px;

            @include breakpoint(large down) {
                &:last-child {
                    padding-bottom: 20px;
                }
            }

            @include breakpoint(xlarge) {
                padding: 16px 0;
            }
        }

        a {
            white-space: nowrap;
            display: block;
            text-decoration: none;
            text-transform: none;
        }
    }

    .mainnav span {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
        display: block;
        width: 10px;
        height: 11px;
        top: 37px;
        right: 16px;
        position: absolute;
        transform-origin: 50% 50%;
        transition: transform 200ms ease-in-out;

        @include breakpoint(xlarge) {
            top: 17px;
            right: -4px;
            position: relative;
            float: right;
        }
    }
}

;@import "sass-embedded-legacy-load-done:244";
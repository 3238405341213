footer.footer {
    .footer-top {
        padding: 48px 0 0 0;

        @include breakpoint(xlarge) {
            padding: 96px 0;
        }
    }

    .footer-bottom {
        padding: 16px 0;

        @include breakpoint(xlarge) {
            padding: 32px 0;
        }
    }

    .heading-h6 {
        margin-bottom: 20px;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .socials {
        padding: 16px 0;
        display: flex;

        @include breakpoint(xlarge) {
            padding: 32px 0;
        }

        ul {
            display: flex;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 44px;
                height: 44px;

                svg {
                    transition: all 350ms ease;
                }

                &:hover svg {
                    transform: scale(1.1);
                }
            }
        }
    }

    .legal {
        display: flex;

        @include breakpoint(large down) {
            flex-direction: column;
        }

        @include breakpoint(xlarge) {
            justify-content: space-between;
        }

        a {
            margin-right: 8px;

            @include breakpoint(xlarge) {
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .right {
            @include breakpoint(xlarge) {
                display: flex;
            }

            .language-selector {
                cursor: pointer;

                ul {
                    display: none;
                    transition: all 350ms ease;
                }

                &.open {
                    ul {
                        display: block;
                    }
                }
            }
        }
    }

    .js-accordion {
        cursor: pointer;
        width: 100%;
        text-align: left;
        outline: none;
        transition: 400ms;

        &.active .heading-h6:after {
            transform: rotate(180deg);
        }

        .heading-h6 {
            display: block;
            position: relative;

            &:after {
                content: '';
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4xMjEiIGhlaWdodD0iMTEuMTgyIiB2aWV3Qm94PSIwIDAgMTguMTIxIDExLjE4MiI+CiAgPHBhdGggaWQ9IlBhdGhfMTk2IiBkYXRhLW5hbWU9IlBhdGggMTk2IiBkPSJNNjMzNCw5NjI0bDgsOCw4LTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MzMyLjkzOSAtOTYyMi45MzkpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMyIvPgo8L3N2Zz4K");
                background-size: 100% auto;
                background-repeat: no-repeat;
                display: block;
                width: 16px;
                height: 10px;
                position: absolute;
                top: 4px;
                right: 30px;
                transform-origin: center;
                transition: transform 200ms ease-out;
            }
        }
    }

    .panel {
        overflow: hidden;

        @include breakpoint(large down) {
            display: none;
            padding: 16px 0;
        }

        @include breakpoint(xlarge) {
            display: block !important;
        }
    }

    @include breakpoint(large down) {
        .contact {
            margin-bottom: 48px;
        }
    }

    .footer-menu li {
        margin-bottom: 16px;
    }

    .phone {
        display: inline-flex;
        align-items: center;
        padding: 12px 24px;
        border-radius: 100px;

        @include breakpoint(small only) {
            width: 100%;
            justify-content: center;
        }

        svg {
            margin-right: 6px;
        }
    }

    @include breakpoint(large down) {
        .newsletter {
            order: -1;
            position: relative;
            margin-bottom: 48px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:240";
.progress {
    .splide__arrows.custom-arrows button {
        background-color: $color-grey-dark-three;
        transition: 200ms ease-out;

        &:hover {
            background-color: rgba($color-grey-dark-three, 0.7);
        }
    }

    .splide-progress {
        background: rgba($color-quote, 0.2);
    }

    .splide-progress-bar {
        background: $color-primary;
    }

    .splide-indicator {
        .current {
            color: $color-primary;
        }

        .total {
            color: $color-black;
        }
    }
}

;@import "sass-embedded-legacy-load-done:300";
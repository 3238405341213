//VENDOR
@import "vendor/foundation/foundation_imports";

//ABSTRACTS
@import "abstracts/custom_mixins";
@import "abstracts/custom_variables";

//BASE
@import "base/generic";

//LAYOUT
@import "layout/header";
@import "layout/footer";
@import "layout/forms";
@import "layout/navigation";

//COMPONENTS
@import "components/modal";
@import "components/search";
@import "components/slider";

//BLOCKS
@import "components/blocks/blockHeroHome";
@import "components/blocks/blockImageTextBig";
@import "components/blocks/blockImageTextSmall";
@import "components/blocks/blockProfessionals";
@import "components/blocks/blockQuoteSlider";
@import "components/blocks/blockDownload";
@import "components/blocks/blockImageMotion";
@import "components/blocks/blockCta";
@import "components/blocks/blockCollage";
@import "components/blocks/blockImageSlider";
@import "components/blocks/blockImageThreeColumns";
@import "components/blocks/blockImageFourColumns";
@import "components/blocks/blockHeroMultipleColumns";
@import "components/blocks/blockImageVideo";
@import "components/blocks/blockSocialFeed";
@import "components/blocks/blockForm";
@import "components/blocks/blockContent";
@import "components/blocks/blockContentImageSwitch";
@import "components/blocks/blockProductConfigurator";
@import "components/blocks/blockDealerLocator";
@import "components/blocks/blockDealerList";

@import "/node_modules/@splidejs/splide/dist/css/splide.min.css";

// prevents to load unstyled content on pageload
html{visibility: visible;}

;@import "sass-embedded-legacy-load-done:4";
.block_search_hero {
    padding: 60px 0;

    .heading-h1 {
        margin-bottom: 40px;
    }

    .search {
        margin-bottom: 48px;

        form {
            display: flex;
        }

        input {
            background-color: $color-white;
            border: none;
            box-shadow: none;
            padding: 12px 16px;
            outline: 0;
            margin-right: 12px;
        }
    }
}

.block_search_results {
    padding: 24px 0;

    @include breakpoint(xlarge) {
        padding: 96px 0;
    }

    .sisea-result {
        margin-top: 48px;
    }

    .simplesearch-paging {
        margin-top: 48px;
        display: flex;

        .simplesearch-page {
            display: flex;
            height: 51px;
            width: 51px;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:248";
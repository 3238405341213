.progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @include breakpoint(xlarge) {
        margin-top: 64px;
    }

    .splide__arrows.custom-arrows {
        display: flex;
        justify-content: space-between;
        width: 86px;
        height: 40px;

        @include breakpoint(xlarge) {
            width: 102px;
            height: 48px;
        }

        .splide__arrow {
            height: 40px;
            width: 40px;
            opacity: 1;
            border-radius: 50%;
            position: relative;
            top: unset;
            transform: none;

            @include breakpoint(xlarge){
                height: 48px;
                width: 48px;
            }

            &:before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.67467 3.56506C8.3426 3.2464 7.81507 3.25728 7.49641 3.58936L1.89885 9.4227C1.58945 9.74513 1.58945 10.2542 1.89885 10.5767L7.49641 16.41C7.81507 16.7421 8.3426 16.7529 8.67467 16.4343C9.00675 16.1156 9.01763 15.5881 8.69898 15.256L3.65508 9.99968L8.69898 4.74332C9.01763 4.41124 9.00675 3.88372 8.67467 3.56506Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.72253 10.026C2.72253 10.4863 3.09563 10.8594 3.55587 10.8594L16.6668 10.8594C17.127 10.8594 17.5001 10.4863 17.5001 10.026C17.5001 9.5658 17.127 9.19271 16.6668 9.19271L3.55587 9.19271C3.09563 9.19271 2.72253 9.5658 2.72253 10.026Z' fill='white'/%3E%3C/svg%3E%0A");
                background-size: auto 100%;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                display: block;
            }

            &.splide__arrow--next {
                right: unset;

                &:before {
                    transform: scale(-1);
                }
            }

            &.splide__arrow--prev {
                left: unset;
            }
        }
    }

    .splide-progress {
        height: 2px;
        width: 100%;
        flex: 1;
        border-radius: 10px;
        margin-right: 24px;

        @include breakpoint(xlarge) {
            margin-right: 32px;
        }

        .splide-progress-bar {
            border-radius: 10px;
            height: 2px;
            transition: width 200ms ease-out;
        }
    }

    .splide-indicator {
        display: flex;

        .current {
            margin-right: 8px;
        }

        .total {
            position: relative;
            margin-left: 8px;

            &:before {
                position: absolute;
                left: -11px;
                content: "/";
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:250";
.block_quote_slider {
    padding: 54px 0;

    @include breakpoint(xlarge) {
        padding: 96px 0;
    }

    .navigation {
        display: flex;
        justify-content: center;

        .splide__track--nav > .splide__list > .splide__slide {
            &:not(.is-active) img {
                opacity: 0.3;
                width: 36px;

                @include breakpoint(xlarge) {
                    width: 64px;
                }
            }

            &.is-active {
                border: none;
            }
        }

        .splide__list {
            align-items: center;
        }

        figure {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .quote-slider {
        margin-top: 32px;

        @include breakpoint(xlarge) {
            margin-top: 64px;
        }

        .quote {
            margin-bottom: 24px;

            @include breakpoint(xlarge) {
                margin-bottom: 32px;
            }
        }

        span {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:260";
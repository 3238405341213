.block_image_video {
    @include breakpoint(xlarge) {
        .content {
            &.grey {
                .heading-h3,
                .heading-h4,
                p {
                    color: $color-grey-dark-one;
                }

                .button {
                    color: $color-grey-dark-one;

                    &:before {
                        border: $color-grey-dark-one solid 1px;
                    }

                    &:after {
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3253 3.56537C11.6574 3.24671 12.1849 3.25759 12.5036 3.58967L18.1011 9.423C18.4105 9.74544 18.4105 10.2545 18.1011 10.577L12.5036 16.4103C12.1849 16.7424 11.6574 16.7533 11.3253 16.4346C10.9932 16.1159 10.9823 15.5884 11.301 15.2563L16.3449 9.99998L11.301 4.74363C10.9823 4.41155 10.9932 3.88402 11.3253 3.56537Z' fill='%231D1D1D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.2774 10.026C17.2774 10.4863 16.9043 10.8594 16.4441 10.8594L3.33321 10.8594C2.87298 10.8594 2.49988 10.4863 2.49988 10.026C2.49988 9.5658 2.87298 9.19271 3.33321 9.19271L16.4441 9.19271C16.9043 9.19271 17.2774 9.5658 17.2774 10.026Z' fill='%231D1D1D'/%3E%3C/svg%3E%0A");
                    }

                    &:hover {
                        &:before {
                            background-color: $color-grey-dark-one;
                        }

                        &:after {
                            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3253 3.56537C11.6573 3.24671 12.1849 3.25759 12.5035 3.58967L18.1011 9.423C18.4105 9.74544 18.4105 10.2545 18.1011 10.577L12.5035 16.4103C12.1849 16.7424 11.6573 16.7533 11.3253 16.4346C10.9932 16.1159 10.9823 15.5884 11.301 15.2563L16.3449 9.99998L11.301 4.74363C10.9823 4.41155 10.9932 3.88402 11.3253 3.56537Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.2774 10.026C17.2774 10.4863 16.9043 10.8594 16.4441 10.8594L3.33318 10.8594C2.87295 10.8594 2.49985 10.4863 2.49985 10.026C2.49985 9.5658 2.87295 9.19271 3.33318 9.19271L16.4441 9.19271C16.9043 9.19271 17.2774 9.5658 17.2774 10.026Z' fill='white'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }

            &.orange {
                .heading-h3,
                .heading-h4,
                p {
                    color: $color-primary;
                }

                .button {
                    color: $color-primary;

                    &:before {
                        border: $color-primary solid 1px;
                    }

                    &:after {
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3253 3.56537C11.6574 3.24671 12.1849 3.25759 12.5036 3.58967L18.1011 9.423C18.4105 9.74544 18.4105 10.2545 18.1011 10.577L12.5036 16.4103C12.1849 16.7424 11.6574 16.7533 11.3253 16.4346C10.9932 16.1159 10.9823 15.5884 11.301 15.2563L16.3449 9.99998L11.301 4.74363C10.9823 4.41155 10.9932 3.88402 11.3253 3.56537Z' fill='%23F08466'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.2774 10.026C17.2774 10.4863 16.9043 10.8594 16.4441 10.8594L3.33321 10.8594C2.87298 10.8594 2.49988 10.4863 2.49988 10.026C2.49988 9.5658 2.87298 9.19271 3.33321 9.19271L16.4441 9.19271C16.9043 9.19271 17.2774 9.5658 17.2774 10.026Z' fill='%23F08466'/%3E%3C/svg%3E");
                    }

                    &:hover {
                        &:before {
                            background-color: $color-primary;
                        }

                        &:after {
                            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3253 3.56537C11.6573 3.24671 12.1849 3.25759 12.5035 3.58967L18.1011 9.423C18.4105 9.74544 18.4105 10.2545 18.1011 10.577L12.5035 16.4103C12.1849 16.7424 11.6573 16.7533 11.3253 16.4346C10.9932 16.1159 10.9823 15.5884 11.301 15.2563L16.3449 9.99998L11.301 4.74363C10.9823 4.41155 10.9932 3.88402 11.3253 3.56537Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.2774 10.026C17.2774 10.4863 16.9043 10.8594 16.4441 10.8594L3.33318 10.8594C2.87295 10.8594 2.49985 10.4863 2.49985 10.026C2.49985 9.5658 2.87295 9.19271 3.33318 9.19271L16.4441 9.19271C16.9043 9.19271 17.2774 9.5658 17.2774 10.026Z' fill='white'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:312";
.block_form {
    padding: 48px 0;

    @include breakpoint(xlarge) {
        padding: 96px 0;
    }

    .heading-h2 {
        margin-bottom: 20px;

        @include breakpoint(xlarge) {
            margin-bottom: 32px;
        }
    }

    .form {
        margin-top: 32px;

        @include breakpoint(xlarge) {
            margin-top: 48px;
        }
    }

    @include breakpoint(large down) {
        .image {
            order: 2;
            margin-top: 32px;
        }
    }

    img {
        width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:282";
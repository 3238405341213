.block_cta {
    padding: 32px 0;

    @include breakpoint(xlarge) {
        padding: 48px 0;
    }

    @include breakpoint(large down) {
        .heading-h2 {
            margin-bottom: 22px;
        }
    }

    .button {
        margin-top: 22px;
    }
}

;@import "sass-embedded-legacy-load-done:266";
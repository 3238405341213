.block_image_text_big {
    padding: 48px 0;

    @include breakpoint(xlarge) {
        padding: 96px 0;
    }

    @include breakpoint(xlarge) {
        &.reversed .media {
            order: 2;
        }

        &:not(.reversed) {
            .content {
                padding-left: 38px;
            }

            video {
                float: right;
            }

            .media img {
                float: right;
            }
        }
    }

    .heading-h2 {
        margin-bottom: 20px;

        @include breakpoint(xlarge) {
            margin-bottom: 32px;
        }
    }

    .media {
        @include breakpoint(large down) {
            order: 2;
            margin-top: 32px;
            padding: 0;
        }

        @include breakpoint(large down) {
            &.has_video {
                position: relative;
                height: 300px;

                video {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        img,
        video {
            @include breakpoint(large down) {
                margin-left: -32px;
                min-width: calc(100% + 64px);
            }

            @include breakpoint(xlarge) {
                width: calc(100% + #{$global-width} / 12 * 1);
                max-width: calc(100% + #{$global-width} / 12 * 1);
            }
        }
    }

    &.animation {
        .image {
            opacity: 0;

            @include breakpoint(xlarge) {
                transform: translateY(-40px);
            }
        }

        video {
            opacity: 0;

            @include breakpoint(xlarge) {
                transform: translateY(-40px);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:254";
.block_search_hero {
    background-color: $color-tertiary;

    .search input {
        color: $color-black;
        font-size: $font-s;
        border: lighten($color-black, 80%) solid 1px;

        &::placeholder {
            color: $color-tertiary;
        }
    }

    .sisea-result .heading-h6 {
        color: $color-black;
    }
}

.block_search_results {
    .heading-h6 {
        margin-bottom: 12px;
    }

    a {
        color: $color-black;
    }

    .simplesearch-highlight {
        font-weight: $bold;
    }

    .simplesearch-page {
        border: 1px solid rgba($color-black, 0.2);
        transition: all 250ms ease-out;

        &:hover,
        &.simplesearch-current-page {
            border-color: $color-black;
        }
    }
}

;@import "sass-embedded-legacy-load-done:299";
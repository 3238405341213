.block_image_three_columns {
    padding: 86px 0;
    overflow: hidden;

    @include breakpoint(xlarge) {
        &:not(.animation) {
            padding: 86px 0 0;
        }
    }

    span,
    .heading-h4 {
        color: $color-white;
    }

    img {
        width: 100%;
    }

    .item {
        cursor: pointer;
    }

    .inner {
        padding: 12px 20px;
        width: calc(100% - 60px);
        margin-left: 16px;
        transform: translateY(-50%);

        @include breakpoint(xlarge) {
            padding: 16px 20px;
            width: calc(100% - 80px);
            margin-left: 20px;
        }

        .button {
            margin-top: 24px;

            @include breakpoint(xlarge) {
                margin-top: 32px;
            }
        }
    }

    @include breakpoint(small only) {
        .splide__track {
            overflow: visible;

            &.hasOverflow {
                width: 85%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:272";
.block_social_feed {
    margin: 48px 0;

    @include breakpoint(xlarge) {
        margin: 96px 0;
    }

    .heading {
        display: flex;

        img {
            min-width: 48px;
            min-height: 48px;
        }

        .heading-h2 {
            margin-left: 20px;
        }
    }

    .content p:last-child {
        margin-bottom: 0;
    }

    .feed {
        margin: 32px 0;

        @include breakpoint(xlarge) {
            margin: 64px 0 0 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:281";
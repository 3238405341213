.block_hero_multiple_columns {
    @include breakpoint(xlarge) {
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
    }

    &.has_element:before {
        height: 28px;
        z-index: 4;

        @include breakpoint(large down) {
            display: none;
        }

        @include breakpoint(xlarge) {
            height: 120px;
        }
    }

    .heading-h1,
    p {
        opacity: 0;
        transform: translateY(55px);
    }

    picture {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        img {
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    .overlay {
        background: linear-gradient(180deg, rgba($color-black, 0.8) 0%, rgba($color-black, 0.55) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
    }

    @include breakpoint(xlarge) {
        .grid-container {
            height: 100vh;
            position: relative;
        }

        .grid-x {
            height: 100%;
            position: relative;
        }
    }

    @include breakpoint(large down) {
        .grid-x {
            padding: 0 16px;
        }
    }

    .item {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(large down) {
            min-height: 700px;
            padding: 48px 24px;
            margin-bottom: 4px;
        }

        @include breakpoint(small only) {
            min-height: 280px;
        }

        &:after {
            content: '';
            background: linear-gradient(180deg, rgba($color-black, 0.8) 0%, rgba($color-black, 0.55) 100%);
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: 2;
        }
    }

    .heading-h1 {
        margin-bottom: 24px;
        position: relative;
        z-index: 3;
    }

    p {
        margin-bottom: 24px;
        position: relative;
        z-index: 3;

        @include breakpoint(xlarge) {
            margin-bottom: 48px;
        }
    }

    .button {
        position: relative;
        z-index: 3;
    }

    .readmore-container {
        max-width: $global-width;
        margin-left: auto;
        margin-right: auto;
        padding-left: 32px;
        padding-right: 32px;
        position: relative;

        .readmore-holder {
            position: absolute;
            bottom: 28px;
            left: 16px;
            z-index: 4;
        }

        .readmore {
            display: inline-flex;
            align-items: center;
            padding: 16px 32px 16px 64px;

            &:before {
                content: '';
                border-radius: 100px;
                width: 48px;
                height: 48px;
                display: block;
                position: absolute;
                left: 0;
                transition: all 350ms ease;
            }

            &:after {
                content: '';
                background-size: 20px 20px;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                left: 14px;
                transition: all 350ms ease;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:277";
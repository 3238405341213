.button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-size: $font-button;
    font-weight: $bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    line-height: 100%;
    min-height: 48px;
    padding: 16px 48px;
    position: relative;
    text-decoration: none;
    overflow: hidden;
    transition: all 350ms ease;

    &:hover {
        text-decoration: none;
    }

    &.button--primary {
        border-radius: 100px;
        color: $color-white;

        &:before {
            content: '';
            border: $color-white solid 1px;
            border-radius: 100px;
            width: 48px;
            height: 48px;
            display: block;
            position: absolute;
            left: 0;
            transform: translateX(-48px);
            transition: all 350ms ease;
        }

        &:after {
            content: '';
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1MzhDMTEuNjU3MyAzLjI0NjcyIDEyLjE4NDkgMy4yNTc2MSAxMi41MDM1IDMuNTg5NjhMMTguMTAxMSA5LjQyMzAyQzE4LjQxMDUgOS43NDU0NSAxOC40MTA1IDEwLjI1NDUgMTguMTAxMSAxMC41NzdMMTIuNTAzNSAxNi40MTAzQzEyLjE4NDkgMTYuNzQyNCAxMS42NTczIDE2Ljc1MzMgMTEuMzI1MyAxNi40MzQ2QzEwLjk5MzIgMTYuMTE2IDEwLjk4MjMgMTUuNTg4NCAxMS4zMDEgMTUuMjU2NEwxNi4zNDQ5IDEwTDExLjMwMSA0Ljc0MzY0QzEwLjk4MjMgNC40MTE1NiAxMC45OTMyIDMuODg0MDQgMTEuMzI1MyAzLjU2NTM4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDEwLjAyNkMxNy4yNzc0IDEwLjQ4NjMgMTYuOTA0MyAxMC44NTk0IDE2LjQ0NDEgMTAuODU5NEwzLjMzMzE4IDEwLjg1OTRDMi44NzI5NSAxMC44NTk0IDIuNDk5ODUgMTAuNDg2MyAyLjQ5OTg1IDEwLjAyNkMyLjQ5OTg1IDkuNTY1OCAyLjg3Mjk1IDkuMTkyNzEgMy4zMzMxOCA5LjE5MjcxTDE2LjQ0NDEgOS4xOTI3MUMxNi45MDQzIDkuMTkyNzEgMTcuMjc3NCA5LjU2NTggMTcuMjc3NCAxMC4wMjZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: 0;
            opacity: 0;
            transform: translateX(20px);
            transition: all 350ms ease;
        }

        &:hover {
            padding: 16px 32px 16px 64px;

            &:before {
                transform: translateX(0);
            }

            &:after {
                opacity: 1;
                transform: translateX(12px);
            }
        }

        &.orange {
            background-color: $color-tertiary;
        }

        &.green {
            background-color: $color-secondary-green-dark;
        }

        &.purple {
            background-color: $color-secondary-purple-dark;
        }

        &.blue {
            background-color: $color-secondary-blue-light;
        }

        &.sand {
            background-color: $color-secondary-sand-dark;
        }
    }

    &.button--secondary {
        background-color: transparent;
        color: $color-grey-dark-one;
        outline: solid 1px;
        outline-offset: -1px;
        border-radius: 100px;

        &:before {
            content: '';
            border-radius: 100px;
            width: 48px;
            height: 48px;
            display: block;
            position: absolute;
            left: 0;
            transform: translateX(-48px);
            transition: all 350ms ease;
        }

        &:after {
            content: '';
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: 0;
            opacity: 0;
            transform: translateX(20px);
            transition: all 350ms ease;
        }

        &.white {
            color: $color-white;
            outline-color: $color-white;

            &:before {
                border: $color-white solid 1px;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1MzhDMTEuNjU3MyAzLjI0NjcyIDEyLjE4NDkgMy4yNTc2MSAxMi41MDM1IDMuNTg5NjhMMTguMTAxMSA5LjQyMzAyQzE4LjQxMDUgOS43NDU0NSAxOC40MTA1IDEwLjI1NDUgMTguMTAxMSAxMC41NzdMMTIuNTAzNSAxNi40MTAzQzEyLjE4NDkgMTYuNzQyNCAxMS42NTczIDE2Ljc1MzMgMTEuMzI1MyAxNi40MzQ2QzEwLjk5MzIgMTYuMTE2IDEwLjk4MjMgMTUuNTg4NCAxMS4zMDEgMTUuMjU2NEwxNi4zNDQ5IDEwTDExLjMwMSA0Ljc0MzY0QzEwLjk4MjMgNC40MTE1NiAxMC45OTMyIDMuODg0MDQgMTEuMzI1MyAzLjU2NTM4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDEwLjAyNkMxNy4yNzc0IDEwLjQ4NjMgMTYuOTA0MyAxMC44NTk0IDE2LjQ0NDEgMTAuODU5NEwzLjMzMzE4IDEwLjg1OTRDMi44NzI5NSAxMC44NTk0IDIuNDk5ODUgMTAuNDg2MyAyLjQ5OTg1IDEwLjAyNkMyLjQ5OTg1IDkuNTY1OCAyLjg3Mjk1IDkuMTkyNzEgMy4zMzMxOCA5LjE5MjcxTDE2LjQ0NDEgOS4xOTI3MUMxNi45MDQzIDkuMTkyNzEgMTcuMjc3NCA5LjU2NTggMTcuMjc3NCAxMC4wMjZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
            }
        }

        &.black {
            color: $color-grey-dark-one;
            outline-color: $color-grey-dark-one;

            &:before {
                border: $color-grey-dark-one solid 1px;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1NEMxMS42NTczIDMuMjQ2NzQgMTIuMTg0OSAzLjI1NzYyIDEyLjUwMzUgMy41ODk3TDE4LjEwMTEgOS40MjMwM0MxOC40MTA1IDkuNzQ1NDcgMTguNDEwNSAxMC4yNTQ2IDE4LjEwMTEgMTAuNTc3TDEyLjUwMzUgMTYuNDEwM0MxMi4xODQ5IDE2Ljc0MjQgMTEuNjU3MyAxNi43NTMzIDExLjMyNTMgMTYuNDM0NkMxMC45OTMyIDE2LjExNiAxMC45ODIzIDE1LjU4ODQgMTEuMzAxIDE1LjI1NjRMMTYuMzQ0OSAxMEwxMS4zMDEgNC43NDM2NkMxMC45ODIzIDQuNDExNTggMTAuOTkzMiAzLjg4NDA1IDExLjMyNTMgMy41NjU0WiIgZmlsbD0iIzFEMUQxRCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjI3NzQgMTAuMDI2QzE3LjI3NzQgMTAuNDg2MyAxNi45MDQzIDEwLjg1OTQgMTYuNDQ0MSAxMC44NTk0TDMuMzMzMTggMTAuODU5NEMyLjg3Mjk1IDEwLjg1OTQgMi40OTk4NSAxMC40ODYzIDIuNDk5ODUgMTAuMDI2QzIuNDk5ODUgOS41NjU4IDIuODcyOTUgOS4xOTI3MSAzLjMzMzE4IDkuMTkyNzFMMTYuNDQ0MSA5LjE5MjcxQzE2LjkwNDMgOS4xOTI3MSAxNy4yNzc0IDkuNTY1OCAxNy4yNzc0IDEwLjAyNloiIGZpbGw9IiMxRDFEMUQiLz4KPC9zdmc+Cg==");
            }
        }

        &:hover {
            padding: 16px 32px 16px 64px;

            &:before {
                transform: translateX(0);
            }

            &:after {
                opacity: 1;
                transform: translateX(12px);
            }
        }
    }

    &.button--tertiary {
        padding: 16px 32px 16px 56px;

        &:before {
            content: '';
            border-radius: 100px;
            width: 48px;
            height: 48px;
            display: block;
            position: absolute;
            left: 0;
            transition: all 350ms ease;
        }

        &:after {
            content: '';
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: 0;
            transform: translateX(12px);
            transition: all 350ms ease;
        }

        &.white {
            color: $color-white;

            &:before {
                border: $color-white solid 1px;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1MzhDMTEuNjU3MyAzLjI0NjcyIDEyLjE4NDkgMy4yNTc2MSAxMi41MDM1IDMuNTg5NjhMMTguMTAxMSA5LjQyMzAyQzE4LjQxMDUgOS43NDU0NSAxOC40MTA1IDEwLjI1NDUgMTguMTAxMSAxMC41NzdMMTIuNTAzNSAxNi40MTAzQzEyLjE4NDkgMTYuNzQyNCAxMS42NTczIDE2Ljc1MzMgMTEuMzI1MyAxNi40MzQ2QzEwLjk5MzIgMTYuMTE2IDEwLjk4MjMgMTUuNTg4NCAxMS4zMDEgMTUuMjU2NEwxNi4zNDQ5IDEwTDExLjMwMSA0Ljc0MzY0QzEwLjk4MjMgNC40MTE1NiAxMC45OTMyIDMuODg0MDQgMTEuMzI1MyAzLjU2NTM4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDEwLjAyNkMxNy4yNzc0IDEwLjQ4NjMgMTYuOTA0MyAxMC44NTk0IDE2LjQ0NDEgMTAuODU5NEwzLjMzMzE4IDEwLjg1OTRDMi44NzI5NSAxMC44NTk0IDIuNDk5ODUgMTAuNDg2MyAyLjQ5OTg1IDEwLjAyNkMyLjQ5OTg1IDkuNTY1OCAyLjg3Mjk1IDkuMTkyNzEgMy4zMzMxOCA5LjE5MjcxTDE2LjQ0NDEgOS4xOTI3MUMxNi45MDQzIDkuMTkyNzEgMTcuMjc3NCA5LjU2NTggMTcuMjc3NCAxMC4wMjZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
            }

            &:hover {
                &:before {
                    background-color: $color-white;
                }

                &:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1NEMxMS42NTczIDMuMjQ2NzQgMTIuMTg0OSAzLjI1NzYyIDEyLjUwMzUgMy41ODk3TDE4LjEwMTEgOS40MjMwM0MxOC40MTA1IDkuNzQ1NDcgMTguNDEwNSAxMC4yNTQ2IDE4LjEwMTEgMTAuNTc3TDEyLjUwMzUgMTYuNDEwM0MxMi4xODQ5IDE2Ljc0MjQgMTEuNjU3MyAxNi43NTMzIDExLjMyNTMgMTYuNDM0NkMxMC45OTMyIDE2LjExNiAxMC45ODIzIDE1LjU4ODQgMTEuMzAxIDE1LjI1NjRMMTYuMzQ0OSAxMEwxMS4zMDEgNC43NDM2NkMxMC45ODIzIDQuNDExNTggMTAuOTkzMiAzLjg4NDA1IDExLjMyNTMgMy41NjU0WiIgZmlsbD0iIzFEMUQxRCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjI3NzQgMTAuMDI2QzE3LjI3NzQgMTAuNDg2MyAxNi45MDQzIDEwLjg1OTQgMTYuNDQ0MSAxMC44NTk0TDMuMzMzMTggMTAuODU5NEMyLjg3Mjk1IDEwLjg1OTQgMi40OTk4NSAxMC40ODYzIDIuNDk5ODUgMTAuMDI2QzIuNDk5ODUgOS41NjU4IDIuODcyOTUgOS4xOTI3MSAzLjMzMzE4IDkuMTkyNzFMMTYuNDQ0MSA5LjE5MjcxQzE2LjkwNDMgOS4xOTI3MSAxNy4yNzc0IDkuNTY1OCAxNy4yNzc0IDEwLjAyNloiIGZpbGw9IiMxRDFEMUQiLz4KPC9zdmc+Cg==");
                }
            }
        }

        &.black {
            color: $color-grey-dark-one;

            &:before {
                border: $color-grey-dark-one solid 1px;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1NEMxMS42NTczIDMuMjQ2NzQgMTIuMTg0OSAzLjI1NzYyIDEyLjUwMzUgMy41ODk3TDE4LjEwMTEgOS40MjMwM0MxOC40MTA1IDkuNzQ1NDcgMTguNDEwNSAxMC4yNTQ2IDE4LjEwMTEgMTAuNTc3TDEyLjUwMzUgMTYuNDEwM0MxMi4xODQ5IDE2Ljc0MjQgMTEuNjU3MyAxNi43NTMzIDExLjMyNTMgMTYuNDM0NkMxMC45OTMyIDE2LjExNiAxMC45ODIzIDE1LjU4ODQgMTEuMzAxIDE1LjI1NjRMMTYuMzQ0OSAxMEwxMS4zMDEgNC43NDM2NkMxMC45ODIzIDQuNDExNTggMTAuOTkzMiAzLjg4NDA1IDExLjMyNTMgMy41NjU0WiIgZmlsbD0iIzFEMUQxRCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjI3NzQgMTAuMDI2QzE3LjI3NzQgMTAuNDg2MyAxNi45MDQzIDEwLjg1OTQgMTYuNDQ0MSAxMC44NTk0TDMuMzMzMTggMTAuODU5NEMyLjg3Mjk1IDEwLjg1OTQgMi40OTk4NSAxMC40ODYzIDIuNDk5ODUgMTAuMDI2QzIuNDk5ODUgOS41NjU4IDIuODcyOTUgOS4xOTI3MSAzLjMzMzE4IDkuMTkyNzFMMTYuNDQ0MSA5LjE5MjcxQzE2LjkwNDMgOS4xOTI3MSAxNy4yNzc0IDkuNTY1OCAxNy4yNzc0IDEwLjAyNloiIGZpbGw9IiMxRDFEMUQiLz4KPC9zdmc+Cg==");
            }

            &:hover {
                &:before {
                    background-color: $color-grey-dark-one;
                }

                &:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1MzhDMTEuNjU3MyAzLjI0NjcyIDEyLjE4NDkgMy4yNTc2MSAxMi41MDM1IDMuNTg5NjhMMTguMTAxMSA5LjQyMzAyQzE4LjQxMDUgOS43NDU0NSAxOC40MTA1IDEwLjI1NDUgMTguMTAxMSAxMC41NzdMMTIuNTAzNSAxNi40MTAzQzEyLjE4NDkgMTYuNzQyNCAxMS42NTczIDE2Ljc1MzMgMTEuMzI1MyAxNi40MzQ2QzEwLjk5MzIgMTYuMTE2IDEwLjk4MjMgMTUuNTg4NCAxMS4zMDEgMTUuMjU2NEwxNi4zNDQ5IDEwTDExLjMwMSA0Ljc0MzY0QzEwLjk4MjMgNC40MTE1NiAxMC45OTMyIDMuODg0MDQgMTEuMzI1MyAzLjU2NTM4WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy4yNzc0IDEwLjAyNkMxNy4yNzc0IDEwLjQ4NjMgMTYuOTA0MyAxMC44NTk0IDE2LjQ0NDEgMTAuODU5NEwzLjMzMzE4IDEwLjg1OTRDMi44NzI5NSAxMC44NTk0IDIuNDk5ODUgMTAuNDg2MyAyLjQ5OTg1IDEwLjAyNkMyLjQ5OTg1IDkuNTY1OCAyLjg3Mjk1IDkuMTkyNzEgMy4zMzMxOCA5LjE5MjcxTDE2LjQ0NDEgOS4xOTI3MUMxNi45MDQzIDkuMTkyNzEgMTcuMjc3NCA5LjU2NTggMTcuMjc3NCAxMC4wMjZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
                }
            }
        }

        &:hover:after {
            transform: translateX(18px);
        }
    }
}

.play-button {
    background-color: $color-primary;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: all 200ms ease-out;

    &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='29' height='32' viewBox='0 0 29 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.6419 13.1808L5.30108 0.338359C3.20131 -0.709267 0.735779 0.817015 0.735779 3.16063V28.841C0.735779 31.1846 3.20583 32.7109 5.30108 31.6633L26.6419 18.8208C28.9675 17.6558 28.9675 14.3413 26.6419 13.1763V13.1808Z' fill='white'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        width: 29px;
        height: 32px;
        transition: transform 150ms ease-out;
    }

    &:hover {
        background-color: darken($color-primary, 0.2);

        &:before {
            transform: scale(1.07);
        }
    }
}

.button-play {
    background-color: $color-black;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    z-index: 2;
    transform: translate(-50%, -50%);

    &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='28' viewBox='0 0 23 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5 13.134C23.1667 13.5189 23.1667 14.4811 22.5 14.866L1.5 26.9904C0.833332 27.3753 0 26.8941 0 26.1243V1.87564C0 1.10584 0.833333 0.624712 1.5 1.00961L22.5 13.134Z' fill='white'/%3E%3C/svg%3E%0A");
        width: 23px;
        height: 28px;
        transition: transform 150ms ease-out;
    }

    &:hover:before {
        transform: scale(1.07);
    }
}

;@import "sass-embedded-legacy-load-done:298";
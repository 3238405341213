.block_image_text_small {
    .inner {
        ul {
            @include custom-bullets-image("checkmark-white.svg", 15px, 12px);
        }

        &.bg-grey-dark {
            color: $color-white;

            .heading-h2 {
                color: $color-white;
            }
        }

        &.bg-white ul {
            @include custom-bullets-image("checkmark-orange.svg", 15px, 12px);
        }

        &.bg-orange .button--primary {
            background-color: $color-tertiary;
        }

        &.bg-green .button--primary {
            background-color: $color-secondary-green-dark;
        }

        &.bg-purple .button--primary {
            background-color: $color-secondary-purple-dark;
        }

        &.bg-blue .button--primary {
            background-color: $color-secondary-blue-light;
        }

        &.bg-sand .button--primary {
            background-color: $color-secondary-sand-dark;
        }
    }
}

;@import "sass-embedded-legacy-load-done:302";
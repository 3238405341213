.block_image_motion {
    padding-bottom: 200px;

    @include breakpoint(large) {
        padding-bottom: 300px;
    }

    @include breakpoint(xlarge) {
        margin: 96px 0;
        padding-bottom: 0;
        min-height: 600px;
    }

    &.reversed {
        .usps {
            @include breakpoint(large down) {
                margin-right: -16px;
            }

            @include breakpoint(xlarge) {
                padding: 28px 0 28px 32px;
                margin-left: calc(#{$global-width} / 12 * 1);
                width: 100%;
            }
        }

        .motion,
        video {
            right: -17%;
        }

        &.animation {
            .motion,
            video {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }

    &:not(.reversed) {
        @include breakpoint(xlarge) {
            .inner {
                padding-left: calc(#{$global-width} / 12 * 1);
            }
        }

        .usps {
            @include breakpoint(large down) {
                margin-left: -16px;
            }
            @include breakpoint(xlarge) {
                padding: 28px 0 28px calc(#{$global-width} / 12 * 1);
            }
        }

        .motion,
        video {
            left: -17%;
        }

        &.animation {
            .motion,
            video {
                opacity: 0;
                transform: translateX(-100px);
            }
        }
    }

    .content {
        margin-top: 48px;
        z-index: 4;

        @include breakpoint(xlarge) {
            margin-top: 60px;
        }

        .inner {
            figure {
                margin-bottom: 12px;

                img {
                    width: 40px;
                    height: 40px;

                    @include breakpoint(xlarge) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }

            .heading-h2 {
                margin-bottom: 20px;

                @include breakpoint(xlarge) {
                    margin-bottom: 32px;
                }
            }
        }

        .usps {
            margin-top: 40px;

            @include breakpoint(large down) {
                margin-bottom: 0;
                padding: 20px 0 32px 16px;
            }

            @include breakpoint(xlarge) {
                margin-top: 64px;
            }
        }
    }

    video {
        object-fit: cover;
        z-index: 2;
    }

    .motion,
    video {
        width: 100vw;
        height: 200px;
        position: absolute;
        aspect-ratio: 16/9;

        @include breakpoint(large down) {
            bottom: 24px;
        }

        @include breakpoint(large) {
            height: 300px;
        }

        @include breakpoint(xlarge) {
            width: calc(59vw + #{$global-width} / 12 * 2.2);
            height: 600px;
        }

        @include breakpoint(xxxlarge) {
            width: calc(59vw + #{$global-width} / 12 * 3);
        }
    }

    .motion {
        z-index: 1;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}

;@import "sass-embedded-legacy-load-done:263";
form {
    .form-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=number],
    input[type=file],
    select,
    textarea {
        padding: 14px 20px 10px 20px;
        outline: 0;
        border: 0;
        -webkit-appearance: none;
        width: 100%;
    }

    select {
        background-size: 12px 7px;
        background-repeat: no-repeat;
        background-position: calc(100% - 20px);
    }

    label {
        width: 100%;
    }

    .cta {
        display: flex;
        justify-content: space-between;

        @include breakpoint(large down) {
            flex-direction: column;
        }

        .button {
            margin: 8px 0;

            @include breakpoint(large down) {
                &.button--tertiary {
                    order: 1;
                }
            }
        }
    }
}

.form-element {
    display: flex;

    @include breakpoint(large down) {
        flex-direction: column;
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @include breakpoint(large down) {
        padding-left: 15px;
        margin-bottom: 20px;
    }

    @include breakpoint(xlarge) {
        margin: 0 15px 15px 15px;
    }

    .form-control--wrapper {
        display: flex;
        flex-direction: column;

        label {
            order: 1;
            margin-bottom: 4px;
        }

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=number],
        input[type=file],
        select,
        textarea {
            order: 2;
        }

        span.error {
            order: 3;
        }
    }
}

.form-control--submit .button.button--primary {
    width: 100%;
    padding: 16px;
    margin-bottom: 8px;
}

.custom-checkbox,
.custom-radio {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 1;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;

        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }
}

.custom-checkbox input:checked ~ .checkmark {
    &:after {
        background-size: 100% auto;
        display: block;
        left: 1px;
        top: 2px;
        width: 12px;
        height: 9px;
    }
}

;@import "sass-embedded-legacy-load-done:243";
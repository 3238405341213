//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: #F08466;
$color-secondary-green: #94AB76;
$color-secondary-green-light: #7F9A5E;
$color-secondary-green-dark: #647E4D;
$color-secondary-blue: #6CA8A3;
$color-secondary-blue-light: #619F9A;
$color-secondary-blue-dark: #4281;
$color-secondary-sand: #CEA076;
$color-secondary-sand-light: #BA8A60;
$color-secondary-sand-dark: #9A6C4C;
$color-secondary-purple: #A7658A;
$color-secondary-purple-light: #B47096;
$color-secondary-purple-dark: #985076;
$color-tertiary: #C7513D;
$color-grey-light-one: #ECECEC;
$color-grey-light-two: #DDDDDD;
$color-grey-light-three: #BBBBBB;
$color-grey-dark-one: #1D1D1D;
$color-grey-dark-two: #2E2D30;
$color-grey-dark-three: #3E3D42;

$color-error: #E91A1B;
$color-form-borders: #CCCCCC;
$color-menu-bg: #F0F0F0;
$color-quote: #585B60;

//Text colors
$color-text: $color-black;
$color-text-light: #5E5E5E;

//Font variables
$mainfont: SegoeUI;

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-h1-hero-max: ceil(($default-size * 3.5)); // ~56px
$heading-h1-hero-min: ceil(($default-size * 1.875)); // ~30px
$heading-h1-max: ceil(($default-size * 3.5)); // ~36px
$heading-h1-min: ceil(($default-size * 1.875)); // ~30px
$heading-h2-max: ceil(($default-size * 3)); // ~32px
$heading-h2-min: ceil(($default-size * 1.5)); // ~24px
$heading-h3-max: ceil(($default-size * 2.25)); // ~28px
$heading-h3-min: ceil(($default-size * 1.25)); // ~20px
$heading-h4-max: ceil(($default-size * 1.75)); // ~24px
$heading-h4-min: ceil(($default-size * 1.25)); // ~20px
$heading-h5: ceil(($default-size * 1.25)); // ~20px
$heading-h6: ceil(($default-size * 0.875)); // ~14px

//Text
$font-l: ceil(($default-size * 1.125)); // ~19px
$font-m: ceil(($default-size * 1.0625)); // ~16px
$font-s: ceil(($default-size * 0.875)); // ~14px
$font-xs: ceil(($default-size * 0.5625)); // ~9px
$font-menu: ceil(($default-size * 1.125)); // ~18px
$font-form: ceil(($default-size * 0.75)); // ~12px

//Other text styles
$font-quote-max: ceil(($default-size * 2.75)); // ~44px
$font-quote-min: ceil(($default-size * 1.5)); // ~24px
$font-button: ceil(($default-size * 0.8125)); // ~13px

//Font settings
$text-settings: (
        'font-heading-hero-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-hero-min, 4vw, $heading-h1-hero-max),
                font-weight: $regular,
                line-height: 110%
        ),
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $regular,
                line-height: 110%
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $regular,
                line-height: 110%
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $regular,
                line-height: 110%
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $regular,
                line-height: 120%
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: $heading-h5,
                font-weight: $regular,
                line-height: 120%
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: $heading-h6,
                font-weight: $regular,
                line-height: 120%
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: $font-l,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: $font-m,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                font-weight: $regular,
                line-height: 130%,
        )
);

;@import "sass-embedded-legacy-load-done:290";
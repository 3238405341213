.block_professionals {
    background-color: $color-black;

    @include breakpoint(xlarge) {
        width: 100%;
        height: 700px;
        overflow: hidden;

        figure.desktop,
        video {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    @include breakpoint(large down) {
        .mobile-inner {
            width: calc(100% + 64px);
            height: 320px;
            margin-left: -32px;
            overflow: hidden;
            position: relative;

            figure,
            video {
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }
    }

    video {
        object-fit: cover;
        z-index: 2;
    }

    figure {
        z-index: 1;
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .grid-container {
        padding-top: 48px;

        @include breakpoint(xlarge) {
            padding: 116px 0;
            position: relative;
            z-index: 4;
        }
    }

    .heading {
        margin-bottom: 32px;

        @include breakpoint(xlarge) {
            margin-bottom: 96px;
        }

        .heading-h2 {
            color: $color-white;

            @include breakpoint(xlarge) {
                max-width: calc(#{$global-width} / 12 * 4);
            }
        }
    }

    .item {
        display: flex;
        cursor: pointer;

        @include breakpoint(large down) {
            margin-top: 16px;
        }

        figure {
            min-width: 33%;
        }

        .inner {
            padding: 20px;
            min-width: 67%;

            .cta {
                display: flex;
                margin-top: 20px;

                .button.button--tertiary {
                    margin-left: auto;
                    padding-right: 0;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:259";
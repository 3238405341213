header.header {
    width: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    transition: all 300ms ease-out;

    @include breakpoint(large down) {
        .top {
            padding-bottom: 24px;
        }
    }

    .topmenu {
        justify-content: space-between;
        padding-top: 8px;

        a {
            display: flex;
            align-items: center;
            margin-left: auto;

            svg {
                margin-right: 4px;
            }
        }

        .usp {
            padding-left: 20px;
            position: relative;

            @include breakpoint(small only) {
                width: calc(100% - 70px);
                display: flex;
                align-items: center;
            }

            &:before {
                content: '';
                background-size: auto 100%;
                width: 13px;
                height: 10px;
                display: block;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
    }

    .language-selector {
        margin-left: 24px;
        position: relative;

        span {
            cursor: pointer;
            display: flex;
        }

        ul {
            position: absolute;
            display: none;
            width: max-content;
            padding: 8px;
            top: 28px;
            left: -8px;
            z-index: 18;
        }

        &.open ul {
            display: block;
        }

        img {
            margin-right: 6px;
        }
    }

    .logo {
        width: 110px;
        display: flex;
        z-index: 11;

        @include breakpoint(large down) {
            margin-left: 16px;
        }

        @include breakpoint(xlarge) {
            width: 152px;
        }
    }

    .hide-close {
        width: 0;
        opacity: 0;
        transition: none;
    }

    .phone,
    .shoppingcart,
    .account,
    .topmenu,
    .logo {
        position: relative;
        z-index: 15;
    }

    .phone {
        margin-left: auto;
    }

    .shoppingcart,
    .account {
        margin-left: 12px;
    }

    .navigation-holder {
        align-items: center;
        margin-top: 24px;

        @include breakpoint(large down) {
            position: relative;
            z-index: 10;
        }

        @include breakpoint(medium) {
            justify-content: space-between;
        }
    }

    .hamburger {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 11;
    }

    //mobile menu open
    @include breakpoint(large down) {
        &.menu-open {
            nav.navigation {
                transition: all 300ms ease-out;
                transform: translateY(0);
            }

            nav.navigation > ul > li.sub-open span {
                transform: rotate(180deg);
            }

            nav.navigation > ul li.sub-open {
                padding-bottom: 0;
            }

            nav.navigation > ul li.sub-open .submenu {
                max-height: 750px;
                display: block;
                transition: max-height 400ms ease-in-out;
            }

            .hamburger {
                background-color: $color-white;
                border-radius: 50%;
            }

            .hide-open {
                display: none;
            }

            .hide-close {
                width: 21px;
                opacity: 1;
                transition: all 175ms cubic-bezier(0.79, 0.33, 0.14, 0.53);

                &.one {
                    transition-delay: 200ms;
                }

                &.two {
                    transition-delay: 400ms;
                }
            }
        }
    }

    &.isScrolled {
        top: 0;

    }

    &:not(.showBreadcrumb) .breadcrumbs {
        opacity: 0;
        height: 0;
        visibility: hidden;
        pointer-events: none;
    }

    + section {
        margin-top: 0;

        @include breakpoint(large down) {
            &:not(.block_hero_home) {
                margin-top: 190px;
            }
        }

        @include breakpoint(xlarge) {
            &:not(.block_hero_multiple_columns) {
                margin-top: 180px;
            }
        }

        &.block_hero_home {
            margin-top: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:237";
.block_image_three_columns {
    &.animation .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:after {
            content: '';
            background: linear-gradient(180deg, $color-white 50%, $color-grey-light-two 72.66%, $color-grey-dark-one 82.03%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .item:hover {
        .button.button--primary,
        .button.button--secondary {
            padding: 16px 32px 16px 64px;

            &:before {
                transform: translateX(0);
            }

            &:after {
                opacity: 1;
                transform: translateX(12px);
            }
        }

        .button.button--tertiary {
            &:before {
                background-color: $color-white;
            }

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zMjUzIDMuNTY1NEMxMS42NTczIDMuMjQ2NzQgMTIuMTg0OSAzLjI1NzYyIDEyLjUwMzUgMy41ODk3TDE4LjEwMTEgOS40MjMwM0MxOC40MTA1IDkuNzQ1NDcgMTguNDEwNSAxMC4yNTQ2IDE4LjEwMTEgMTAuNTc3TDEyLjUwMzUgMTYuNDEwM0MxMi4xODQ5IDE2Ljc0MjQgMTEuNjU3MyAxNi43NTMzIDExLjMyNTMgMTYuNDM0NkMxMC45OTMyIDE2LjExNiAxMC45ODIzIDE1LjU4ODQgMTEuMzAxIDE1LjI1NjRMMTYuMzQ0OSAxMEwxMS4zMDEgNC43NDM2NkMxMC45ODIzIDQuNDExNTggMTAuOTkzMiAzLjg4NDA1IDExLjMyNTMgMy41NjU0WiIgZmlsbD0iIzFEMUQxRCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjI3NzQgMTAuMDI2QzE3LjI3NzQgMTAuNDg2MyAxNi45MDQzIDEwLjg1OTQgMTYuNDQ0MSAxMC44NTk0TDMuMzMzMTggMTAuODU5NEMyLjg3Mjk1IDEwLjg1OTQgMi40OTk4NSAxMC40ODYzIDIuNDk5ODUgMTAuMDI2QzIuNDk5ODUgOS41NjU4IDIuODcyOTUgOS4xOTI3MSAzLjMzMzE4IDkuMTkyNzFMMTYuNDQ0MSA5LjE5MjcxQzE2LjkwNDMgOS4xOTI3MSAxNy4yNzc0IDkuNTY1OCAxNy4yNzc0IDEwLjAyNloiIGZpbGw9IiMxRDFEMUQiLz4KPC9zdmc+Cg==");
                transform: translateX(18px);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:307";
.block_image_four_columns {
    &.has_element:before {
        background-color: rgba($color-primary, 0.85);
    }

    .item:hover {
        .button.button--primary {
            padding: 16px 32px 16px 64px;

            &:before {
                transform: translateX(0);
            }

            &:after {
                opacity: 1;
                transform: translateX(12px);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:308";
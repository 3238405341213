// Do not animate items on resize. See resize.js
.noanim * {
    transition: none !important;
}

html,
body {
    height: 100%;
    color: $color-black;
}

body.menu-open,
body.filters-open,
body.modal-open {
    overflow: hidden;
}

.off-canvas-wrapper {
    min-height: 100%;
}

section,
figure,
footer {
    position: relative;
}

.relative {
    position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 16px 0;
    margin: 0;
    z-index: 2;
    position: relative;

    marker {
        display: none;
    }

    li {
        position: relative;
        margin-right: 32px;
        display: inline-block;

        &:not(:last-child)::after {
            position: absolute;
            top: 0;
            right: -20px;
            content: "/";
            width: 7px;
            height: 21px;
            display: block;
        }
    }
}

.has_element {
    position: relative;

    &:before {
        content: '';
        width: 30vw;
        height: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        z-index: -1;

        @include breakpoint(large down) {
            right: 0;
        }

        @include breakpoint(xxlarge) {
            width: 27vw;
            left: 0;
        }

        @include breakpoint(xxxlarge) {
            width: 35vw;
        }
    }
}

#form-input-productFamily,
label[for=form-input-productFamily],
#form-input-productModel,
label[for=form-input-productModel],
#form-input-productType,
label[for=form-input-productType],
#form-input-productMeasurements,
label[for=form-input-productMeasurements] {
    display: none;
}

;@import "sass-embedded-legacy-load-done:236";
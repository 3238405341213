.block_quote_slider {
    .quote p {
        @include font-heading-h2;
        line-height: 31.2px;
        color: $color-quote;

        @include breakpoint(xlarge) {
            line-height: 57.2px;
        }

        strong {
            color: $color-grey-dark-one;
        }
    }

    .quote-name {
        color: $color-grey-dark-one;
        font-size: $font-menu;
    }

    .name-small {
        color: rgba($color-grey-dark-one, 0.65);
        font-size: $heading-h6;
    }
}

;@import "sass-embedded-legacy-load-done:304";
footer.footer {
    background-color: $color-grey-dark-one;

    p {
        color: $color-white;
    }

    .heading-h6,
    .js-accordion {
        color: $color-white;
        font-weight: $bold;
        text-transform: uppercase;
    }

    .footer-menu a {
        color: $color-grey-light-three;
        font-size: $font-m;
        line-height: 24px;
    }

    .footer-bottom {
        font-size: $font-s;
        font-weight: $regular;
        line-height: 21px;
        color: $color-grey-light-three;
        border-top: 1px solid $color-grey-light-three;

        a {
            color: $color-grey-light-three;
        }
    }

    .phone {
        color: $color-grey-light-three;
        font-size: $font-m;
        border: 1px solid $color-grey-dark-three;
    }
}

;@import "sass-embedded-legacy-load-done:295";